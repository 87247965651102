import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
  namespaced: true,
  state() {
    let externalId = null;
    let cartId = null;
    let paymentExternalId = localStorage.getItem('paymentExternalId');

    try {
      externalId = localStorage.getItem('ex_cart');
      if (!externalId) {
        externalId = uuidv4();
        localStorage.setItem('ex_cart', externalId);
      }

      cartId = localStorage.getItem('cart_id');
    } catch (e) {
      externalId = uuidv4();
    }

    return {
      cart: { id: cartId, itens: [] },
      external_id: externalId,
      buscado: false,
      loading: false,
      timmer: null,
    };
  },

  mutations: {
    SAVE_PAYMENT_EXTERNAL_ID(state, paymentExternalId) {
      state.paymentExternalId = paymentExternalId;
      localStorage.setItem('paymentExternalId', paymentExternalId);
    },

    CLEAR_PAYMENT_EXTERNAL_ID(state) {
      state.paymentExternalId = null;
      localStorage.removeItem('paymentExternalId');
    },

    SET_CART(state, cart) {
      state.cart = cart;
      state.buscado = true;

      if (cart.id) {
        localStorage.setItem('cart_id', cart.id);
      }
    },

    ADD_TO_CART(state, produto) {
      const index = state.cart.itens.findIndex(cartItem => cartItem.produto_id === produto.id);
      if (index > -1) {
        if (state.cart.itens[index].ativo) {
          state.cart.itens[index].qtde++;
        } else {
          state.cart.itens[index].qtde = 1
          state.cart.itens[index].ativo = true
        }

      } else {
        state.cart.itens.push({
          produto: produto,
          produto_id: produto.id,
          qtde: 1,
          ativo: true,
          valor_unitario: produto.valor_venda
        });
      }
      this.dispatch('ecommerce/saveCart');
    },

    REMOVE_FROM_CART(state, produto) {
      const existingItemIndex = state.cart.itens.findIndex(cartItem => cartItem.produto_id === produto.produto_id);
      if (existingItemIndex !== -1) {
        state.cart.itens[existingItemIndex].ativo = false;
      }
    },

    CLEAR_CART(state) {
      state.cart = { itens: [] };
      state.cart.id = null;
      state.buscado = false;

      try {
        localStorage.removeItem('ex_cart');
        localStorage.removeItem('cart_id');
      } catch (e) {
        console.error("Erro ao limpar ex_cart do localStorage:", e);
      }

      state.external_id = uuidv4();
      localStorage.setItem('ex_cart', state.external_id);
    },

    UPDATE_PRODUCT_QUANTITY(state, { produto_id, qtde }) {
      const index = state.cart.itens.findIndex(cartItem => cartItem.produto_id === produto_id);

      if (index !== -1) {
        const produto = state.cart.itens[index];

        if (qtde > 0) {
          produto.qtde = qtde;
        } else {
          state.cart.itens.splice(index, 1);
        }
      }
    }

  },

  actions: {
    savePaymentExternalId({ commit }, paymentExternalId) {
      commit('SAVE_PAYMENT_EXTERNAL_ID', paymentExternalId);
    },

    clearPaymentExternalId({ commit }) {
      commit('CLEAR_PAYMENT_EXTERNAL_ID');
    },
    async updateProductQuantity({ commit, dispatch }, { produto_id, qtde }) {
      commit('UPDATE_PRODUCT_QUANTITY', { produto_id, qtde });
      await dispatch('saveCart');
    },
    async saveCart({ state, dispatch }) {
      if (state.cart.itens.length === 0) {
        return;
      }

      if (state.cart.id) {
        await dispatch('putCart');
      } else {
        await dispatch('postCart');
      }
    },

    async postCart({ commit, state, dispatch }) {
      state.loading = true;

      const moedaId = 2

      const obj = {
        external_id: state.external_id,
        filial_id: 1,
        moeda_id: moedaId,
        itens: state.cart.itens.map(item => ({
          produto_id: String(item.produto.id),
          qtde: String(item.qtde),
          armazem_id: 1,
          valor_unitario: String(item.valor_unitario),
          ativo: item.ativo,
        })),
      };

      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: 'Bearer ' + token } : {};

        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URI}/ecom/venda/carrinho`, obj, { headers });
          commit('SET_CART', response.data);
        } catch (error) {
          if (error.response?.data?.errors?.external_id?.[0] === "O valor informado para o campo external id já está em uso.") {
            await dispatch('putCart');
          } else {
            throw error;
          }
        }
      } catch (error) {
        console.error('Erro ao salvar carrinho:', error);
      } finally {
        state.loading = false;
      }
    },

    async putCart({ commit, state }) {
      if (!state.cart.id) {
        return;
      }

      state.loading = true;

      const obj = {
        id: state.cart.id,
        external_id: state.external_id,
        filial_id: 1,
        moeda_id: state.cart.moeda_id,
        itens: state.cart.itens.map(item => ({
          produto_id: String(item.produto.id),
          qtde: String(item.qtde),
          armazem_id: 1,
          valor_unitario: String(item.valor_unitario),
          ativo: item.ativo,
        }))
      };

      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: 'Bearer ' + token } : {};
        const response = await axios.put(`${process.env.VUE_APP_API_URI}/ecom/venda/carrinho/${state.cart.id}`, obj, { headers });

        /* commit('SET_CART', response.data); */
      } catch (error) {
        console.error('Erro ao atualizar carrinho:', error);
      } finally {
        state.loading = false;
      }
    },

    async getCart({ commit, state, rootState, dispatch }, moedaId = null) {
      await dispatch('language/changeLanguage', localStorage.getItem('locale') || 'pt-BR');

      const currentLanguage = rootState.language.currentLanguage;
      const user = rootState.auth.user;

      if (!user) {
        await dispatch('auth/fetchUser');
      }

      if (moedaId === null) {
        if (user?.estrangeiro !== undefined) {
          moedaId = user.estrangeiro ? 2 : 3;
        } else {

          if (currentLanguage === 'pt-BR') {
            moedaId = 2;
          } else if (currentLanguage === 'es-PY') {
            moedaId = 3;
          } else {
            moedaId = 2;
          }
        }
      }

      console.log('Moeda ID usada na requisição:', moedaId);

      const cartId = localStorage.getItem('cart_id');
      if (!cartId) {
        console.log('No cart_id found. Skipping getCart.');
        return;
      }

      state.loading = true;

      try {
        const externalId = state.external_id;
        let apiUrl = `${process.env.VUE_APP_API_URI}/ecom/venda/carrinho/${externalId}`;
        if (moedaId !== null) {
          apiUrl += `?moeda_id=${moedaId}`;
        }

        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get(apiUrl, { headers });

        let cart = response.data;
        cart.itens = cart.itens.map((x) => {
          x.qtde = Number(x.qtde);
          return x;
        });

        commit('SET_CART', cart);
        return cart;
      } catch (error) {
        console.error('Erro ao buscar carrinho na API:', error);
        throw error;
      } finally {
        state.loading = false;
        state.buscado = true;
      }
    },


    addToCart({ commit, dispatch }, item) {
      commit('ADD_TO_CART', item);
      dispatch('saveCart');
    },

    removeFromCart({ commit, dispatch }, item) {
      commit('REMOVE_FROM_CART', item);
      dispatch('saveCart');
    },

    clearCart({ commit }) {
      commit('CLEAR_CART');
    },

    changeCart({ commit, dispatch }, item) {
      commit('CHANGE_CART', item);
      dispatch('saveCart');
    },
  },

  getters: {
    getCart(state) {
      if (state.buscado) {
        return state.cart;
      }
      return {};
    },

    getAdicionado: (state) => (id) => {
      return state.cart.itens.some(item => item.produto_id === id && item.ativo);
    },

    getLoading(state) {
      return state.loading;
    },
  },
};
