import HomeView from '../views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';

export const generalRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sobre-nos',
    name: 'SobreNos',
    component: AboutView,
  },
];
