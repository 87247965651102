<template>
  <div class="product-page">
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="currentCategory && currentCategory.departments.length" class="category-sidebar">
      <div>
        <h3 style="font-size: 20px; padding: 10px">{{ currentCategory.name }}</h3>
        <ul class="department-list">
          <li v-for="(department, deptIndex) in currentCategory.departments" :key="deptIndex">
            <a href="#" class="department-link" @click.prevent="selectDepartment(department, currentCategory)"
              :class="{ 'selected-department': selectedDepartmentId === department.id }">
              {{ department.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="brand-filter">
        <h4 style="font-size: 20px; padding: 10px">{{ $t('categories.brands') }}</h4>
        <ul class="brand-list">
          <li v-for="(brand, brandIndex) in brands" :key="brandIndex" class="marca">
            <label>
              <input type="checkbox" :value="brand.id" :checked="selectedBrandIds.includes(brand.id)"
                @change="filterByBrand(brand.id)">
              <span class="check"></span>
              {{ brand.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="product-list-section">
      <div class="header">
        <h2>{{ selectedTitle }}</h2>
        <div class="sort-options">
          <div id="print-select-container">
            <select id="print-select" v-model="selectedPrintOption" @change="handlePrintChange">
              <option value="">IMPRIMIR</option>
              <option value="grade">GRADE</option>
              <option value="lista">LISTA</option>
              <option value="txt">TXT</option>
            </select>
          </div>
          <div class="custom-select-wrapper">
            <select id="sort" v-model="sortOption">
              <option value="">{{ $t('categories.sortOptions.placeholder') }}</option>
              <option value="valor_venda:asc">{{ $t('categories.sortOptions.priceLow') }}</option>
              <option value="valor_venda:desc">{{ $t('categories.sortOptions.priceHigh') }}</option>
              <option value="nome:asc">{{ $t('categories.sortOptions.nameAsc') }}</option>
              <option value="nome:desc">{{ $t('categories.sortOptions.nameDesc') }}</option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="isLoading">
        <ProdutosSkeleton :isLoading="isLoading" />
      </div>
      <div v-else-if="productsLoaded && filteredProducts.length === 0">
        <p>{{ $t('categories.noProducts') }}</p>
      </div>

      <div class="product-list">
        <ProductCard v-for="(product, index) in sortedProducts" :key="index" :product="product"
          :currentPage="currentPage" :produtosAddCart="produtosAddCart" :defaultImage="defaultImage" />
      </div>

      <div class="pagination-container" v-if="totalPages > 1">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">
          {{ $t('pagination.previous') }}
        </button>
        <div class="pagination-numbers">
          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
            :class="{ active: page === currentPage }">
            {{ page }}
          </button>
          <span v-if="totalPages > visiblePages[visiblePages.length - 1]">...</span>
          <button v-if="currentPage < totalPages - 4" @click="goToPage(totalPages)">
            {{ totalPages }}
          </button>
        </div>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">
          {{ $t('pagination.next') }}
        </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchCategories, fetchBrands, fetchProducts } from '@/services/apiService';
import logo from "../assets/images/logo-branca.png"
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ProductCard from '@/components/produtos/ProductCard.vue';
import { nextTick } from 'vue';
import { formatPrice } from '@/services/apiService'; 


const { locale } = useI18n();
const categories = ref([]);
const filteredProducts = ref([]);
const selectedTitle = ref('Produtos');
const sortOption = ref('');
const defaultImage = require('../assets/images/produto-sem-imagem.jpg');
const error = ref(null);
const selectedCategoryId = ref(null);
const selectedBrand = ref(null);
const selectedDepartmentId = ref(null);
const searchText = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalProducts = ref(0);
const isLoading = ref(false);
const productsLoaded = ref(false);
const brands = ref([]);
const selectedBrandIds = ref([]);
const cart = ref([]);
const produtosAddCart = ref([]);
const externalId = ref(null);
const store = useStore();
const route = useRoute();
const router = useRouter();
const selectedPrintOption = ref('');

const currentCategory = computed(() => {
  return categories.value.find(c => c.slug === route.params.categoriaSlug) || null;
});
const adicionarAoCarrinho = (product) => {
  if (product.tem_estoque) {
    store.commit('ecommerce/ADD_TO_CART', {
      ...product,
    });
  } else {
    alert('Este produto está indisponível no momento!');
  }
};
const handlePrintChange = () => {
  if (selectedPrintOption.value === 'grade') {
    printProductListGrid();
  } else if (selectedPrintOption.value === 'lista') {
    printProductListTable();
  } else if (selectedPrintOption.value === 'txt') {
    generateProductListTxt();
  }

  if (selectedPrintOption.value === '') {
    const selectElement = document.getElementById('print-select');
    const pdfOption = selectElement.querySelector('option[value=""]');
    if (pdfOption) {
      pdfOption.remove();
    }
  }

  selectedPrintOption.value = '';
};

const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  if (isNaN(precoNumerico)) return 'Preço inválido';

  const idioma = localStorage.getItem('locale') || 'pt-BR';

  const simboloMoeda = idioma === 'es-PY' ? 'G$' : 'U$';

  return idioma === 'es-PY'
    ? `${simboloMoeda} ${precoNumerico.toFixed(0).replace('.', ',')}`
    : `${simboloMoeda} ${precoNumerico.toFixed(2).replace('.', ',')}`;
};


const goToPage = (page) => {
  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      page: page
    }
  });

  fetchProductsList(page);
};

const nextPage = () => {
  if (currentPage.value < totalPages.value && filteredProducts.value.length > 0) {
    goToPage(currentPage.value + 1);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    goToPage(currentPage.value - 1);
  }
};


const selectDepartment = async (department, category) => {
  selectedDepartmentId.value = department.id;
  selectedTitle.value = department.name;
  selectedBrandIds.value = [];

  nextTick(() => {
    // Atualizando a URL com os parâmetros de categoria e departamento
    router.push({
      name: 'produtos',
      params: {
        categoriaSlug: category.slug,
        departamentoSlug: department.slug
      },
      query: { ...route.query } // Mantendo outros query params
    });
  });

  goToPage(1);
  await fetchProductsList(1);
  await fetchBrandsByDepartment(department.slug);
};


const fetchBrandsByDepartment = async (departmentSlug) => {
  try {
    isLoading.value = true;
    const response = await fetch(`${process.env.VUE_APP_API_URI}/api/public/departments/${departmentSlug}`);
    const data = await response.json();

    if (data.brands && data.brands.length) {
      brands.value = data.brands.map(brand => ({
        id: brand.id,
        name: brand.nome,
        slug: brand.slug
      })).sort((a, b) => a.name.localeCompare(b.name));
    } else {
      console.error("Nenhuma marca encontrada para o departamento");
    }
  } catch (error) {
    console.error("Erro ao buscar marcas", error);
  } finally {
    isLoading.value = false;
  }
};


const selectBrand = async (brand) => {
  if (selectedBrandIds.value.length === brands.value.length) {
    selectedBrandIds.value = [brand.id];
  } else {
    const index = selectedBrandIds.value.indexOf(brand.id);
    if (index > -1) {
      selectedBrandIds.value.splice(index, 1);
    } else {
      selectedBrandIds.value.push(brand.id);
    }
  }


  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      brandSlug: selectedBrandIds.value.join(',')
    }
  });

  goToPage(1);

  fetchProductsList(currentPage.value);
};


const sortedProducts = computed(() => {
  return filteredProducts.value.map(product => {
    const price = parseFloat(product.valor_venda);
    let formattedPrice = '';

    if (!isNaN(price) && product.moeda) {
      formattedPrice = formatPrice(price, product.moeda.id);
    } else {
      formattedPrice = ''; 
    }

    return {
      ...product,
      formattedPrice: `${formattedPrice}`,
    };
  });
});

const fetchCategoriesList = async () => {
  isLoading.value = true;
  try {
    const data = await fetchCategories();
    const sortedCategories = data.sort((a, b) => a.name.localeCompare(b.name));

    sortedCategories.forEach(category => {
      if (category.departments) {
        category.departments.sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    categories.value = sortedCategories;
  } catch (err) {
    error.value = 'Erro ao buscar categorias e departamentos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};

const fetchBrandsList = async () => {
  isLoading.value = true;
  try {
    const data = await fetchBrands();
    brands.value = data.sort((a, b) => a.name.localeCompare(b.name));
    selectedBrandIds.value = brands.value.map(brand => brand.id);
  } catch (err) {
    error.value = 'Erro ao buscar marcas.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};

const filterByBrand = (brandId) => {
  const index = selectedBrandIds.value.indexOf(brandId);
  if (index > -1) {
    selectedBrandIds.value.splice(index, 1);
  } else {
    selectedBrandIds.value.push(brandId);
  }

  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      brandSlug: selectedBrandIds.value.join(',')
    }
  });

  fetchProductsList(currentPage.value);
};

const fetchProductsList = async (page = 1) => {
  currentPage.value = page;
  isLoading.value = true;
  productsLoaded.value = false;
  window.scrollTo(0, 0);

  try {
    filteredProducts.value = [];

    let orderBy = 'id';
    let order = 'desc';

    if (sortOption.value && sortOption.value.includes(':')) {
      const [sortField, sortOrder] = sortOption.value.split(':');
      orderBy = sortField;
      order = sortOrder;
    }

    const params = {
      text: route.query.text || searchText.value,
      category_id: selectedCategoryId.value,
      department_id: selectedDepartmentId.value,
      brands_ids: selectedBrandIds.value.length ? selectedBrandIds.value.join(',') : null,
      page: currentPage.value,
      order_by: `tem_estoque,${orderBy}`,
      order: `desc,${order}`,
      all_products: 1
    };

    const data = await fetchProducts(params);

    filteredProducts.value = data.data || [];
    totalProducts.value = data.total;
    totalPages.value = Math.ceil(totalProducts.value / 20);

    store.commit('setMoedaId', data.moedaId);
    store.commit('setLocale', data.locale);

  } catch (err) {
    error.value = 'Erro ao buscar produtos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
    productsLoaded.value = true;
  }
};


const fetchBrandsByCategory = async (categorySlug) => {
  try {
    isLoading.value = true;
    const response = await fetch(`${process.env.VUE_APP_API_URI}/api/public/categories/${categorySlug}`);
    const data = await response.json();


    if (data.brands && data.brands.length) {
      brands.value = data.brands.map(brand => ({
        id: brand.id,
        name: brand.nome,
        slug: brand.slug
      })).sort((a, b) => a.name.localeCompare(b.name));
    } else {
      console.error("Nenhuma marca encontrada para a categoria");
    }
  } catch (error) {
    console.error("Erro ao buscar marcas", error);
  } finally {
    isLoading.value = false;
  }
};
const printProductListGrid = async () => {
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const printWindow = window.open('', '', 'width=800, height=600');
  const searchQueryText = searchText.value ? `"${searchText.value}"` : '';

  const headerTitle = selectedTitle.value === "Produtos" ? searchQueryText : `${selectedTitle.value} ${searchQueryText}`;

  printWindow.document.write(`
    <html>
      <head>
        <style>
          /* Estilos para a impressão */
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          .print-header {
            width: 100%;
            padding: 10px 20px; 
            background-color: #000;
            color: #fff;
            border-bottom: 2px solid #ddd;
            margin-bottom: 10px; 
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            page-break-before: always;
          }
          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          .logo-container {
            flex: 1;
          }
          .store-logo {
            max-height: 120px;
            object-fit: contain;
            width: auto;
          }
          .header-text {
            flex: 2;
            text-align: right;
          }
          .date {
            font-size: 12px;
            color: #555;
            margin-bottom: 5px;
          }
          .price-disclaimer {
            font-size: 10px;
            color: #888;
            margin-bottom: 5px;
            font-style: italic;
          }

          .product-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            justify-items: center;
            margin: 20px auto;
            max-width: 100%;
          }
          .product-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ddd;
            padding: 10px;
            border-radius: 10px;
            width: 120px;
            height: 250px;
            box-sizing: border-box;
          }
          .product-image-container {
            width: 100%;
            height: 130px;
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .product-image {
            width: auto;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
          }
          .product-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            text-align: center;
          }
          .product-name {
            font-size: 0.6rem;
            font-family: 'Lato', sans-serif;
            color: #2b2b2b;
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 1.5;
            overflow: visible;
            text-overflow: initial;
            display: block;
          }
          .product-price {
            font-size: 16px;
            font-family: 'Lato', sans-serif;
            color: #3ea12a;
            font-weight: 700;
            margin: 5px 0;
          }
          .product-code {
            font-size: 12px;
            color: #757575;
            line-height: 1.667;
          }
          .out-of-stock-tag {
            color: red;
            font-weight: bold;
          }

          @media print {
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            @page {
              margin: 0;
            }
            * {
              box-sizing: border-box;
            }
            .product-list {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 10px;
              justify-items: center;
              margin: 20px auto;
              max-width: 100%;
              page-break-inside: avoid;
            }
            .product-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #ddd;
              padding: 10px;
              border-radius: 10px;
              width: 120px;
              height: 250px;
              box-sizing: border-box;
              page-break-inside: avoid;
            }
            .print-header {
              background-color: #000 !important;
              color: #fff !important;
              padding: 10px 20px;
              margin: 0;
            }
          }
        </style>
      </head>
      <body>
  `);

  printWindow.document.write(`
    <div class="print-header">
      <div class="header-content">
        <div class="logo-container">
          <img src="${logo}" alt="GENOVE" class="store-logo">
        </div>
        <div class="header-text">
          <p class="date">Data de Geração: ${new Date().toLocaleDateString()}</p>
          <p class="price-disclaimer">OS PREÇOS PODEM SOFRER ALTERAÇÕES SEM PRÉVIO AVISO.</p>
          <p class="price-disclaimer">OS VALORES NÃO INCLUEM I.V.A</p>
          <h2>${headerTitle}</h2>
        </div>
      </div>
    </div>
    <div class="product-list">
  `);

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const params = {
        text: route.query.text || searchText.value,
        category_id: selectedCategoryId.value,
        department_id: selectedDepartmentId.value,
        brands_ids: selectedBrandIds.value.length ? selectedBrandIds.value.join(',') : null,
        page: currentPage,
        order_by: 'tem_estoque,id',
        order: 'desc,desc',
        all_products: 1,
      };

      try {
        const data = await fetchProducts(params);
        allProducts = allProducts.concat(data.data);
        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
      }

      currentPage++;
    }

    return allProducts;
  };
  const productsToPrint = (await fetchAllFilteredProducts()).filter(product => product.tem_estoque);

  // Aplica a ordenação no conjunto completo de produtos
  if (sortOption.value === 'valor_venda:asc') {
    productsToPrint.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToPrint.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToPrint.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToPrint.sort((a, b) => b.nome.localeCompare(a.nome));
  }
  if (productsToPrint.length === 0) {
    printWindow.document.write('<p>Nenhum produto disponível para exibição.</p>');
  } else {
    let productIndex = 0;
    const firstPageLimit = 15;
    const itemsPerPage = 20;

    for (let i = 0; i < firstPageLimit && productIndex < productsToPrint.length; i++) {
      const product = productsToPrint[productIndex];
      printWindow.document.write(`
        <div class="product-item">
          <div class="product-image-container">
            <img src="${product.imagens[0]?.url || defaultImage}" alt="Imagem do produto ${product.nome}" class="product-image"/>
          </div>
          <div class="product-info">
            <p class="product-name">${product.nome}</p>
            <p class="product-price">${formatarPreco(product.valor_venda)}</p>
            <p class="product-code">${product.codigo}</p>
          </div>
        </div>
      `);
      productIndex++;
    }

    while (productIndex < productsToPrint.length) {
      printWindow.document.write('</div><div style="page-break-after: always;"></div><div class="product-list">');
      for (let i = 0; i < itemsPerPage && productIndex < productsToPrint.length; i++) {
        const product = productsToPrint[productIndex];
        printWindow.document.write(`
          <div class="product-item">
            <div class="product-image-container">
              <img src="${product.imagens[0]?.url || defaultImage}" alt="Imagem do produto ${product.nome}" class="product-image"/>
            </div>
            <div class="product-info">
              <p class="product-name">${product.nome}</p>
              <p class="product-price">${formatarPreco(product.valor_venda)}</p>
              <p class="product-code">${product.codigo}</p>
            </div>
          </div>
        `);
        productIndex++;
      }
    }
  }

  printWindow.document.write('</div></body></html>');
  printWindow.document.close();
};
const printProductListTable = async () => {
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const printWindow = window.open('', '', 'width=800, height=600');
  const searchQueryText = searchText.value ? `"${searchText.value}"` : '';
  const headerTitle = selectedTitle.value === "Produtos" ? searchQueryText : `${selectedTitle.value} ${searchQueryText}`;

  printWindow.document.write(`
    <html>
      <head>
        <style>
          /* Estilos para a impressão */
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          .print-header {
            width: 100%;
            padding: 10px 20px; 
            background-color: #000;
            color: #fff;
            border-bottom: 2px solid #ddd;
            margin-bottom: 10px; 
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            page-break-before: always;
          }

          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .logo-container {
            flex: 1;
          }

          .store-logo {
            max-height: 120px;
            object-fit: contain;
            width: auto;
          }

          .header-text {
            flex: 2;
            text-align: right;
          }

          .date {
            font-size: 12px;
            color: #555;
            margin-bottom: 5px;
          }

          .price-disclaimer {
            font-size: 10px;
            color: #888;
            margin-bottom: 5px;
            font-style: italic;
          }

          .product-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
          }

          .product-table th, .product-table td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
          }

          .product-table th {
            background-color: #f4f4f4;
          }

          .product-name {
            font-size: 14px;
            font-family: 'Lato', sans-serif;
            color: #2b2b2b;
            margin-bottom: 8px;
            font-weight: 500;
          }

          .product-price {
            font-size: 16px;
            font-family: 'Lato', sans-serif;
            color: #3ea12a;
            font-weight: 700;
            margin: 5px 0;
          }

          .product-code {
            font-size: 12px;
            color: #757575;
            line-height: 1.667;
          }

          @media print {
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            @page {
              margin: 0;
            }
            * {
              box-sizing: border-box;
            }
            .product-table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            .product-table th, .product-table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center;
            }
            .product-table th {
              background-color: #f4f4f4;
            }
            .print-header {
              background-color: #000 !important;
              color: #fff !important;
              padding: 10px 20px;
              margin: 0;
            }
          }
        </style>
      </head>
      <body>
  `);

  printWindow.document.write(`
    <div class="print-header">
      <div class="header-content">
        <div class="logo-container">
          <img src="${logo}" alt="GENOVE" class="store-logo">
        </div>
        <div class="header-text">
          <p class="date">Data de Geração: ${new Date().toLocaleDateString()}</p>
          <p class="price-disclaimer">OS PREÇOS PODEM SOFRER ALTERAÇÕES SEM PRÉVIO AVISO.</p>
          <p class="price-disclaimer">OS VALORES NÃO INCLUEM I.V.A</p>
          <h2>${headerTitle}</h2>
        </div>
      </div>
    </div>
    <table class="product-table">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th>Preço</th>
        </tr>
      </thead>
      <tbody>
  `);

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const params = {
        text: route.query.text || searchText.value,
        category_id: selectedCategoryId.value,
        department_id: selectedDepartmentId.value,
        brands_ids: selectedBrandIds.value.length ? selectedBrandIds.value.join(',') : null,
        page: currentPage,
        order_by: 'tem_estoque,id',
        order: 'desc,desc',
        all_products: 1,
      };

      try {
        const data = await fetchProducts(params);
        allProducts = allProducts.concat(data.data);
        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
      }

      currentPage++;
    }

    return allProducts;
  };

  const productsToPrint = (await fetchAllFilteredProducts()).filter(product => product.tem_estoque);

  // Aplica a ordenação no conjunto completo de produtos
  if (sortOption.value === 'valor_venda:asc') {
    productsToPrint.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToPrint.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToPrint.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToPrint.sort((a, b) => b.nome.localeCompare(a.nome));
  }

  if (productsToPrint.length === 0) {
    printWindow.document.write('<tr><td colspan="3">Nenhum produto disponível para exibição.</td></tr>');
  } else {
    productsToPrint.forEach(product => {
      printWindow.document.write(`
        <tr>
          <td class="product-code">${product.codigo}</td>
          <td class="product-name">${product.nome}</td>
          <td class="product-price">${formatarPreco(product.valor_venda)}</td>
        </tr>
      `);
    });
  }

  printWindow.document.write(`
      </tbody>
    </table>
  </body>
</html>
  `);

  printWindow.document.close();
};
const generateProductListTxt = async () => {
  // Verifica se há produtos para exibir
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const params = {
        text: route.query.text || searchText.value,
        category_id: selectedCategoryId.value,
        department_id: selectedDepartmentId.value,
        brands_ids: selectedBrandIds.value.length ? selectedBrandIds.value.join(',') : null,
        page: currentPage,
        order_by: 'tem_estoque,id',
        order: 'desc,desc',
        all_products: 1,
      };

      try {
        const data = await fetchProducts(params);
        allProducts = allProducts.concat(data.data);
        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
      }

      currentPage++;
    }

    return allProducts;
  };

  const productsToExport = (await fetchAllFilteredProducts()).filter(product => product.tem_estoque);

  // Aplica a ordenação no conjunto completo de produtos
  if (sortOption.value === 'valor_venda:asc') {
    productsToExport.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToExport.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToExport.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToExport.sort((a, b) => b.nome.localeCompare(a.nome));
  }

  if (productsToExport.length === 0) {
    alert('Nenhum produto disponível para exportação.');
    return;
  }

  // Cabeçalho do arquivo
  let content = `Lista de Preços (${new Date().toLocaleString()})\nGENOVE (www.genove.com.py)\n\n`;
  content += `Código - Descrição - Preço\n`;
  content += `---------------------------------------------------------------------------------------------------------------------------\n`;

  productsToExport.forEach(product => {
    const codigo = product.codigo.toString().padEnd(12, ' ').trim();
    const nome = product.nome.padEnd(90, ' ').trim();
    const valor = product.valor_venda
      ? `*U$ ${parseFloat(product.valor_venda).toFixed(2)}*`
      : '*N/A*';
    content += `${codigo} - ${nome} - ${valor}\n`;
  });


  // Cria o arquivo .txt
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'Lista_de_Precos.txt';
  link.click();


};

watch(currentCategory, async (newCategory) => {
  if (newCategory) {
    await fetchBrandsByCategory(newCategory.slug);
  }
});

watch([() => route.params.departamentoSlug, () => route.params.categoriaSlug, () => route.query.page, () => route.query.text],
  async ([departamentoSlug, categoriaSlug, page, text], [oldDepartamentoSlug, oldCategoriaSlug, oldPage, oldText]) => {
    if (categoriaSlug !== oldCategoriaSlug) {
      const categoryItem = categories.value.find(c => c.slug === categoriaSlug);
      selectedCategoryId.value = categoryItem ? categoryItem.id : null;
      selectedTitle.value = categoryItem ? categoryItem.name : 'Produtos';
    }

    if (departamentoSlug !== oldDepartamentoSlug) {
      const departmentItem = currentCategory.value?.departments.find(d => d.slug === departamentoSlug);
      selectedDepartmentId.value = departmentItem ? departmentItem.id : null;
      selectedTitle.value = departmentItem ? departmentItem.name : selectedTitle.value;
    }

    currentPage.value = parseInt(page) || 1;
    searchText.value = text || '';
    await fetchProductsList(currentPage.value);
  });

watch(sortOption, (newSortOption) => {
  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      sort: newSortOption || undefined
    }
  });

  fetchProductsList(currentPage.value);
});


onMounted(async () => {
  currentPage.value = parseInt(sessionStorage.getItem('currentPage')) || 1;

  await fetchCategoriesList();
  await fetchBrandsList();

  if (route.params.categoriaSlug) {
    const categoryItem = categories.value.find(c => c.slug === route.params.categoriaSlug);
    selectedCategoryId.value = categoryItem ? categoryItem.id : null;
    selectedTitle.value = categoryItem ? categoryItem.name : 'Produtos';
  }

  if (route.params.departamentoSlug && currentCategory.value) {
    const departmentItem = currentCategory.value.departments.find(d => d.slug === route.params.departamentoSlug);
    selectedDepartmentId.value = departmentItem ? departmentItem.id : null;
    selectedTitle.value = departmentItem ? departmentItem.name : selectedTitle.value;
  }
  if (route.query.brandSlug) {
    selectedBrandIds.value = route.query.brandSlug.split(',').map(slug => parseInt(slug));
  }
  if (route.query.sort) {
    sortOption.value = route.query.sort;
  }

  await fetchProductsList(currentPage.value);
});


watch(currentPage, (newPage) => {
  sessionStorage.setItem('currentPage', newPage);
});

const visiblePages = computed(() => {
  let pages = [];
  if (totalPages.value <= 5) {
    pages = Array.from({ length: totalPages.value }, (_, i) => i + 1);
  } else {
    const start = Math.max(1, currentPage.value - 2);
    const end = Math.min(totalPages.value, start + 4);

    pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  return pages;
});

</script>



<style scoped>
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.brand-filter {
  margin: 20px 0;
}

.brand-list {
  list-style: none;
  padding: 0;
  margin-left: 20px;
}

.marca {
  margin-bottom: 10px;
  position: relative;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  display: none;
}

.check {
  width: 15px;
  height: 15px;
  border: 2px solid #C99C5A;
  border-radius: 4px;
  margin-right: 4px;
  position: relative;
}

.check:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked+.check:after {
  display: block;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.selected-department {
  font-weight: bold;
  color: #ffffff;
  background-color: #C99C5A;

  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.department-link.selected-department {
  color: #ffffff;
  font-size: 14px;
}

.product-page {
  display: flex;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  max-width: 1500px;
  margin: 0 auto;

}


.category-name {
  color: black
}

.category-sidebar {
  width: 280px;
  padding-top: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  position: relative;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.category-item {
  position: relative;
  margin-bottom: 12px;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid transparent;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
}

.category-link:hover,
.category-link.active {
  background-color: #C99C5A;
  color: #ffffff;
  border-color: #C99C5A;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.department-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;

}

.department-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.department-link:hover {
  background: #C99C5A;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.category-link i {
  transition: transform 0.3s ease;
}

.product-list-section {
  flex: 1;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}


.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  flex: 1;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.out-of-stock-tag {
  color: red;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.product-price {
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 3px 0;
  font-weight: 700;
  margin-top: 10px;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    align-items: center;

  }

  .product-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sort-options select {
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid transparent;
  background-color: #707070;
  color: #e7c084;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.sort-options select:hover {
  background-color: #5a5a5a;
  border-color: #555;
}

.sort-options select:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.print-button-container .print-button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.print-button-container .print-button:hover {
  background-color: #f4f4f4;
  border-color: #888;
  color: #000;
}

.print-button-container .print-button:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.print-button-container .print-button:active {
  background-color: #e0e0e0;
  border-color: #707070;
  color: #333;
}

.print-button-container .print-button i {
  margin-right: 8px;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 10px;
  position: relative;
}

.pagination-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, #C99C5A, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
}

.pagination-button:hover {
  background: linear-gradient(145deg, #C4A77D, #C99C5A);
  transform: scale(1.05);
}

.pagination-button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-numbers {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.pagination-numbers button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid transparent;
  border-radius: 50px;
  background: #fff;
  color: #C99C5A;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

.pagination-numbers button.active {
  background: #C99C5A;
  color: #fff;
}

.pagination-numbers button:hover {
  background: #C99C5A;
  color: #fff;
}

@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .print-button-container .print-button {
    display: none;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }

  .pagination-numbers {
    display: none;
  }

  .pagination-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #c99c5a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.add-to-cart-overlay {
  background-color: #C99C5A;
  /* Cor de fundo personalizada */
  color: white;
  /* Texto branco */
  border: none;
  /* Remove bordas padrão */
  padding: 12px 30px;
  /* Tamanho do botão */
  font-size: 16px;
  /* Tamanho da fonte */
  font-weight: bold;
  /* Texto em negrito */
  border-radius: 30px;
  /* Bordas arredondadas */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* Cursor de clique */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  /* Sombra para destacar o botão */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Suaviza animações */
}
@media (max-width: 640px) {
  #print-select-container {
    display: none;
  }
}

.add-to-cart-overlay:hover {
  background-color: #b2884d;
  /* Cor mais escura ao passar o mouse */
  transform: scale(1.05);
  /* Leve aumento no tamanho para feedback visual */
}

.add-to-cart-overlay i {
  margin-right: 10px;
  /* Espaçamento entre o ícone e o texto */
  font-size: 18px;
  /* Tamanho do ícone */
}

.add-to-cart-btn,
.added-to-cart {
  background-color: #C99C5A;
  /* Cor de fundo personalizada */
  color: white;
  /* Cor do texto */
  border: none;
  /* Remove borda */
  padding: 8px 16px;
  /* Menos espaço interno (reduzindo o tamanho) */
  font-size: 12px;
  /* Reduz o tamanho da fonte */
  font-weight: bold;
  /* Texto em negrito */
  border-radius: 10px;
  /* Bordas arredondadas */
  cursor: pointer;
  /* Cursor de clique */
  display: inline-flex;
  /* Alinha o texto com o ícone */
  align-items: center;
  /* Centraliza o conteúdo */
  justify-content: center;
  /* Garante que o conteúdo esteja centralizado */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Animações suaves */
}

.add-to-cart-btn:hover,
.added-to-cart:hover {
  background-color: #b2884d;
  /* Cor mais escura ao passar o mouse */
  transform: scale(1.05);
  /* Aumenta o botão ligeiramente */
}

.add-to-cart-btn i,
.added-to-cart i {
  margin-right: 6px;
  /* Reduz o espaço entre o ícone e o texto */
  font-size: 14px;
  /* Ajuste o tamanho do ícone */
}
</style>
