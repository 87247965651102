<template>
  <div v-if="isCartVisible" class="cart-overlay" @click="handleOverlayClick">
    <div class="cart-container slide-in" @click.stop>
      <!-- Header -->
      <div class="cart-header">
        <h2>{{ $t('cart.title') }}</h2>
        <button aria-label="Fechar carrinho" @click="toggleCart" class="close-cart-button">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <!-- Cart Items -->
      <div class="cart-items">
        <ul v-if="filteredCartItems.length > 0">
          <li v-for="(item, index) in filteredCartItems" :key="index" class="cart-item">
            <div class="item-info">
              <img :src="item.produto?.imagens[0]?.url || defaultImage" alt="Produto" class="item-image" />
              <div class="item-details">
                <span class="item-name">{{ item.produto.nome }}</span>
                <!-- Modificar para mostrar a moeda conforme o moeda_id -->
                <span class="item-price">{{ formattedPrice(item) }}</span>
              </div>
              <!-- Remover item posicionado no canto superior direito -->
              <button @click="removeItem(item)" class="remove-item" aria-label="Remover item do carrinho">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
            <div class="item-actions">
              <button @click="updateQuantity(item, item.qtde - 1)" class="quantity-button">-</button>
              <span class="item-quantity">{{ Math.floor(item.qtde) }}</span>
              <button @click="updateQuantity(item, item.qtde + 1)" class="quantity-button">+</button>
            </div>
          </li>

        </ul>
        <p v-else class="empty-cart">{{ $t('cart.empty_cart') }}</p>
      </div>
      <button v-if="filteredCartItems.length > 0 && !isConfirmingClear" @click="startClearCartConfirmation"
        class="clear-cart-button small">
        {{ $t('cart.clear_cart') }}
      </button>
      <button v-if="isConfirmingClear" @click="clearCart" class="clear-cart-button confirm-button">
        {{ $t('cart.confirm_clear_cart') }}
      </button>

      <!-- Pedido Resumo -->
      <div v-if="filteredCartItems.length > 0" class="order-summary">
        <p class="summary-total">{{ $t('cart.total') }} {{ formattedTotalPrice }}</p>
      </div>

      <!-- Footer -->
      <div v-if="filteredCartItems.length > 0" class="cart-footer">
        <div class="buttons">
          <button @click="continueShopping" class="continue-shopping-button">
            {{ $t('cart.continue_shopping') }}
          </button>
          <button @click="goToCheckout" class="checkout-button" aria-label="Ir para o checkout">
            {{ $t('cart.checkout') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  name: "CartView",
  data() {
    return {
      isCartVisible: false,
      defaultImage: require('@/assets/images/produto-sem-imagem.jpg'),
      isConfirmingClear: false,
      confirmTimeout: null,
      moeda_id: null,
    };
  },
  computed: {
    cart() {
      return this.$store.state.ecommerce.cart || { itens: [], moeda_id: 2 };
    },

    filteredCartItems() {
      return this.cart.itens
        .filter(item => item.ativo !== false)
        .map(item => ({
          ...item,
          produto: {
            ...item.produto,
            imagens: item.produto.imagens && item.produto.imagens.length > 0
              ? item.produto.imagens
              : [{ url: this.defaultImage }],
          },
        }));
    },

    totalPrice() {
      return this.filteredCartItems.reduce(
        (total, item) => total + (parseFloat(item.valor_unitario) || 0) * Math.floor(item.qtde),
        0
      );
    },

    formattedTotalPrice() {
      const total = this.totalPrice;

      // Escolhe o símbolo da moeda com base no moeda_id
      if (this.moeda_id === 2) { // Para USD
        // Formatação para U$
        return `U$ ${total.toFixed(2)}`; // Exibe sempre com 2 casas decimais
      } else { // Para PYG
        // Formatação para G$ sem decimais
        return `G$ ${total.toLocaleString('pt-BR')}`; // Usa separador de milhar e sem casas decimais
      }
    },
  },

  methods: {
    startClearCartConfirmation() {
      this.isConfirmingClear = true;
      this.resetClearCartConfirmation();
    },

    resetClearCartConfirmation() {
      if (this.confirmTimeout) clearTimeout(this.confirmTimeout);
      this.confirmTimeout = setTimeout(() => {
        this.isConfirmingClear = false;
      }, 3000);
    },

    clearCart() {
      this.$store.dispatch('ecommerce/clearCart');
      this.isConfirmingClear = false;
      if (this.confirmTimeout) clearTimeout(this.confirmTimeout);
    },

    toggleCart() {
      this.isCartVisible = !this.isCartVisible;
    },

    handleOverlayClick() {
      this.toggleCart();
    },

    continueShopping() {
      this.toggleCart();
    },

    goToCheckout() {
      this.$router.push('/checkout');
      this.toggleCart();
    },

    updateQuantity(item, newQuantity) {
      const quantity = Math.max(1, Math.round(newQuantity));

      if (quantity !== item.qtde) {
        this.$store.dispatch('ecommerce/updateProductQuantity', {
          produto_id: item.produto_id,
          qtde: quantity
        });
      }
    },

    removeItem(item) {
      this.$store.dispatch('ecommerce/removeFromCart', item);
    },

    formattedPrice(item) {
      const price = parseFloat(item.valor_unitario) || 0;

      // Escolhe o símbolo da moeda com base no moeda_id
      if (this.moeda_id === 2) { // Para USD
        // Formatação para U$
        return `U$ ${price.toFixed(2)}`; // Exibe sempre com 2 casas decimais
      } else { // Para PYG
        // Formatação para G$ sem decimais
        return `G$ ${price.toLocaleString('pt-BR')}`; // Usa separador de milhar e sem casas decimais
      }
    },
  },

  watch: {
    'cart.itens': function (newItems) {
      console.log('Carrinho atualizado no Vuex:', newItems);
    },

    cart: {
      immediate: true,
      handler(newCart) {
        if (newCart.moeda_id) {
          this.moeda_id = newCart.moeda_id;
        }
      }
    }
  },

  created() {
    eventBus.on("toggle-cart", this.toggleCart);

    this.$store.dispatch('ecommerce/getCart')
      .then(cart => {
        if (cart.moeda_id) {
          this.moeda_id = cart.moeda_id;
        }
      })
      .catch(error => {
        console.error('Erro ao buscar carrinho:', error);
      });
  },

  beforeUnmount() {
    if (this.confirmTimeout) clearTimeout(this.confirmTimeout);
  },
};
</script>

<style scoped>
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-end;
  z-index: 1500;
}

.cart-container {
  width: 400px;
  background-color: #ffffff;
  border-radius: 12px 0 0 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out, fadeIn 0.3s ease-out;
}

.cart-header h2 {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 2px solid #f0f0f0;
}

.cart-header h2 i {
  margin-right: 10px;
  font-size: 1.3em;
  vertical-align: middle;
}

.cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-button {
  background-color: #ff9800;
  color: white;
  padding: 10px;
  margin: 10px
}

.confirm-button:hover {
  background-color: #e67e22;
}

.clear-cart-button.small {
  background: none;
  border: none;
  color: #f44336;
  font-size: 0.9em;
  cursor: pointer;
}

.clear-cart-button.confirm-button {
  background-color: #f44336;
  color: white;
  padding: 6px 12px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.clear-cart-button.confirm-button:hover {
  background-color: #d32f2f;
}

.close-cart-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 50%;
}

.close-cart-button:hover {
  color: #d32f2f;
  background-color: rgba(0, 0, 0, 0.1);
}

.cart-items {
  flex: 1;
  overflow-y: auto;
}

.order-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.summary-total {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;

}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  transition: background 0.3s ease;
}

.cart-item:hover {
  background-color: #f8f8f8;
}

.item-info {
  display: flex;
  align-items: center;
}

.item-image {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-weight: 600;
  color: #333;
}

.item-price {
  color: #ff9800;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 5px;
}

.item-actions {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-right: 20px;
}

.item-actions button {
  padding: 6px 12px;
  border: none;
  background: #4caf50;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.item-actions button.quantity-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: #e0e0e0;
  color: #333;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  padding: 0;
}

.item-actions button.quantity-button:hover {
  background: #d6d6d6;
  transform: scale(1.1);
}

.remove-item {
  background: none;
  color: #8a8a8a;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.2s ease;
}

.remove-item:hover {
  transform: scale(1.1);
}

.remove-item:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

.cart-footer .total p {
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;

}

.cart-footer .buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkout-button {
  width: 100%;
  padding: 12px;
  background: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  border-radius: 6px;
}

.slide-in {
  transform: translateX(100%);
  animation: slideIn 0.3s forwards ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.checkout-button:hover {
  background: #45a049;
}

.continue-shopping-button {
  width: 100%;
  padding: 14px;
  background: #ffa500;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: bold;
}

.continue-shopping-button:hover {
  background: #ff8c00;
}

.empty-cart {
  text-align: center;
  color: #777;
  margin-top: 20px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .cart-container {
    width: 100%;
    border-radius: 0;
  }

  .cart-header h2 {
    font-size: 1.6em;
  }
}

@media (max-width: 768px) {
  .cart-footer .buttons {
    margin-bottom: 20px;
  }

  .cart-container {
    padding-bottom: 80px;
  }
}
</style>
