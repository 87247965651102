<template>
    <div class="frete-calculator">
      <label for="cidade">Selecione sua cidade:</label>
      <select v-model="cidadeSelecionada" id="cidade">
        <option v-for="cidade in cidades" :key="cidade.codigo_ciudad" :value="cidade">
          {{ cidade.denominacion }} ({{ cidade.departamento_denominacion }})
        </option>
      </select>
  
      <div v-if="cidadeSelecionada">
        <p>Cidade: {{ cidadeSelecionada.denominacion }}</p>
        <p>Departamento: {{ cidadeSelecionada.departamento_denominacion }}</p>
        <p>Frete estimado: {{ formatarMoeda(calcularFrete(cidadeSelecionada)) }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        cidadeSelecionada: null,
        cidades: [
          {
            codigo_ciudad: "PY0000",
            denominacion: "Asunción",
            codigo_departamento: "0",
            codigo_pais: "PY",
            ubicacion_geografica: "(-57.613382,-25.294086)",
            departamento_denominacion: "Capital",
          },
          {
            codigo_ciudad: "PY0101",
            denominacion: "Concepción",
            codigo_departamento: "1",
            codigo_pais: "PY",
            ubicacion_geografica: "(-57.438528,-23.405802)",
            departamento_denominacion: "Concepción",
          },
          {
            codigo_ciudad: "PY0102",
            denominacion: "Belén",
            codigo_departamento: "1",
            codigo_pais: "PY",
            ubicacion_geografica: null,
            departamento_denominacion: "Concepción",
          },
        ],
      };
    },
    methods: {
      calcularFrete(cidade) {
        const tarifas = {
          "Capital": 5.00,
          "Concepción": 10.00
        };
        return tarifas[cidade.departamento_denominacion] || 15.00; 
      },
      formatarMoeda(valor) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(valor);
      }
    }
  };
  </script>
  
  <style scoped>
  .frete-calculator {
    max-width: 400px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  label {
    font-weight: bold;
  }
  
  select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  p {
    margin: 5px 0;
  }
  </style>
  