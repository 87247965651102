<template>
  <div class="container my-5 unique-carousel">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="mb-0">{{ title }}</h2>
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-primary btn-sm me-2" type="button" @click="left"
          aria-label="Move to previous slide"
          :disabled="loading || transitioning || !canNavigateManual || currentIndex === 0">
          <i class="fas fa-chevron-left"></i>
        </button>
        <button class="btn btn-outline-primary btn-sm" type="button" @click="right" aria-label="Move to next slide"
          :disabled="loading || transitioning || !canNavigateManual || currentIndex === groupedProdutos.length - 1">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>

    <div class="linha-abaixo-titulo"></div>

    <div :id="carouselId" class="carousel slide">
      <div class="carousel-inner">
        <div v-if="loading" class="carousel-item active skeleton-container">
          <div class="carousel-item-content">
            <div v-for="n in produtosPorSlide" :key="n" class="product-item skeleton">
              <div class="product-image-container">
                <div class="product-image skeleton-image"></div>
              </div>
              <div class="product-info">
                <p class="product-name skeleton-text"></p>
                <p class="product-price skeleton-text"></p>
                <p class="product-code skeleton-text"></p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="carousel-item" v-for="(group, index) in groupedProdutos" :key="index"
          :class="{ active: index === currentIndex }">
          <div class="carousel-item-content">
            <ProductCard v-for="produto in group" :key="produto.id" :product="produto" :currentPage="currentPage"
              :produtosAddCart="produtosAddCart" :defaultImage="semImagem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { Carousel } from 'bootstrap';
import semImagem from '../../assets/images/produto-sem-imagem.jpg';
import { useWindowSize } from '@vueuse/core';
import store from '@/store';
import ProductCard from '../produtos/ProductCard.vue';
import { fetchProductsByBrandId } from '@/services/apiService';
import { useI18n } from 'vue-i18n';
import { formatPrice } from '@/services/apiService';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  brandId: {
    type: Number,
    required: true
  },
  carouselId: {
    type: String,
    required: true
  },
  produtosAddCart: {
    type: Array,
    default: () => []
  }
});

const { locale } = useI18n();
const produtosDestaque = ref([]);
const loading = ref(true);
const transitioning = ref(false);
const canNavigateManual = ref(true);
const { width } = useWindowSize();

const produtosPorSlide = computed(() => {
  if (width.value >= 1600) return 4;
  if (width.value >= 1200) return 3;
  if (width.value >= 768) return 2;
  return 1;
});

const groupedProdutos = computed(() => {
  const numProdutos = produtosPorSlide.value;
  return Array.from({ length: Math.ceil(sortedProducts.value.length / numProdutos) }, (_, index) =>
    sortedProducts.value.slice(index * numProdutos, (index + 1) * numProdutos)
  );
});

const sortedProducts = computed(() => {
  return produtosDestaque.value.map(product => {
    const price = parseFloat(product.valor_venda);
    let formattedPrice = '';

    if (!isNaN(price) && product.moeda) {
      formattedPrice = formatPrice(price, product.moeda.id);
    } else {
      formattedPrice = ''; 
    }

    return {
      ...product,
      formattedPrice: `${formattedPrice}`,
    };
  });
});

const currentIndex = ref(0);
let carouselInstance;
let autoPlayInterval;

const fetchFeaturedProducts = async () => {
  loading.value = true;
  try {
    const response = await fetchProductsByBrandId(props.brandId, {
      tem_estoque: true,
      order_by: 'ordem',
      order: 'asc',
    });
    produtosDestaque.value = response.data || [];
  } catch (error) {
    console.error('Erro ao buscar produtos destacados:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchFeaturedProducts();

  const carouselElement = document.getElementById(props.carouselId);
  carouselInstance = new Carousel(carouselElement, {
    interval: false,
    wrap: false,
    ride: false,
    slide: true
  });

  carouselElement.addEventListener('slide.bs.carousel', () => {
    transitioning.value = true;
    canNavigateManual.value = false;
  });

  carouselElement.addEventListener('slid.bs.carousel', () => {
    transitioning.value = false;
    canNavigateManual.value = true;
    updateButtonStates();
  });

  startAutoPlay();
});

const adicionarAoCarrinho = (produto) => {
  if (produto.tem_estoque) {
    store.commit('ecommerce/ADD_TO_CART', {
      ...produto,
    });
  } else {
    alert('Este produto está indisponível no momento!');
  }
};

const resetAutoPlay = () => {
  clearInterval(autoPlayInterval);
  startAutoPlay();
};

const left = () => {
  if (!loading.value && !transitioning.value) {
    transitioning.value = true;

    if (currentIndex.value > 0) {
      carouselInstance.prev();
      currentIndex.value = Math.max(currentIndex.value - 1, 0);
    } else {
      carouselInstance.to(groupedProdutos.value.length - 1);
      currentIndex.value = groupedProdutos.value.length - 1;
    }

    updateButtonStates();
    resetAutoPlay();
  }
};

const right = () => {
  if (!loading.value && !transitioning.value) {
    transitioning.value = true;

    if (currentIndex.value < groupedProdutos.value.length - 1) {
      carouselInstance.next();
      currentIndex.value = Math.min(currentIndex.value + 1, groupedProdutos.value.length - 1);
    } else {
      carouselInstance.to(0);
      currentIndex.value = 0;
    }

    updateButtonStates();
    resetAutoPlay();
  }
};

const startAutoPlay = () => {
  autoPlayInterval = setInterval(() => {
    if (!transitioning.value && canNavigateManual.value) {
      right();
    }
  }, 5000);
};

const updateButtonStates = () => {
};
</script>


<style scoped>
.unique-carousel h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.unique-carousel .linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
}

.unique-carousel .btn-outline-primary {
  color: #777777;
  border-color: #777777;
}

.unique-carousel .btn-outline-primary:hover {
  background-color: #C99C5A;
  color: white;
  border-color: #C99C5A;
}
.unique-carousel .carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out; 
}

.unique-carousel .carousel-item-content {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: nowrap;
  padding: 8px
}

.unique-carousel .product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  flex: 1 1 auto;
  max-width: 25%;
  min-width: 220px;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;

}

.unique-carousel .product-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.unique-carousel .product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.unique-carousel .product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.unique-carousel .product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.unique-carousel .product-name {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
}

.unique-carousel .product-price {
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  color: #C99C5A;
  margin: 5px 0;
  font-weight: 700;
  margin-top: 10px;
}

.unique-carousel .product-code {
  font-size: 0.9rem;
  color: #757575;
  line-height: 1.667;
  margin-top: auto;
  margin-bottom: 15px;
}

.unique-carousel .skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.unique-carousel .skeleton {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(to right,
      #eeeeee 4%,
      #dddddd 25%,
      #eeeeee 36%);
  background-size: 1000px 100%;
  position: relative;
  overflow: hidden;
}

.unique-carousel .skeleton-image {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.unique-carousel .skeleton-text {
  background-color: #eeeeee;
  height: 1rem;
  width: 70%;
  margin: 0 auto;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.add-to-cart-container {
  margin-top: 10px;

}

.add-to-cart-btn {
  background-color: #C99C5A;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #b38a4b;
}

.add-to-cart-btn i {
  margin-right: 8px;
}
</style>
