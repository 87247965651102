<template>
  <div class="product-page">
    <LoadingSpinner :isLoading="isLoading" />

    <!-- Lista de Produtos -->
    <div class="product-list-section">
      <!-- Lista de Categorias -->
      <div class="categories-container" v-if="categories.length > 0">
        <ul class="category-list">
          <li v-for="category in categories" :key="category.id" class="category-item">
            <a @click.prevent="selectCategory(category)" class="category-link"
              :class="{ active: selectedCategory && selectedCategory.id === category.id }">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="header">
        <h2>{{ selectedTitle }}</h2>
        <div class="sort-options">
          <div id="print-select-container">
            <select id="print-select" v-model="selectedPrintOption" @change="handlePrintChange">
              <option value="">IMPRIMIR</option>
              <option value="grade">GRADE</option>
              <option value="lista">LISTA</option>
              <option value="txt">TXT</option>
            </select>
          </div>

          <div class="custom-select-wrapper">
            <select id="sort" v-model="sortOption" :aria-label="$t('categories.sortOptions.placeholder')">
              <option value="">{{ $t('categories.sortOptions.placeholder') }}</option>
              <option value="valor_venda:asc">{{ $t('categories.sortOptions.priceLow') }}</option>
              <option value="valor_venda:desc">{{ $t('categories.sortOptions.priceHigh') }}</option>
              <option value="nome:asc">{{ $t('categories.sortOptions.nameAsc') }}</option>
              <option value="nome:desc">{{ $t('categories.sortOptions.nameDesc') }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="linha-abaixo-titulo"></div>
      <div v-if="filteredProducts.length === 0">
        <p>{{ $t('messages.noProductsForBrand') }}</p>
      </div>
      <div v-else class="product-list">
        <ProductCard v-for="(product, index) in sortedProducts" :key="index" :product="product"
          :currentPage="currentPage.value" :produtosAddCart="produtosAddCart || []" :defaultImage="defaultImage" />
      </div>
      <div class="pagination-container" v-if="totalPages > 1">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">
          {{ $t('pagination.previous') }}
        </button>
        <div class="pagination-numbers">
          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
            :class="{ active: page === currentPage }">
            {{ page }}
          </button>
          <span v-if="totalPages > visiblePages[visiblePages.length - 1]">...</span>
          <button v-if="currentPage < totalPages - 4" @click="goToPage(totalPages)">
            {{ totalPages }}
          </button>
        </div>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">
          {{ $t('pagination.next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from '@/components/template/loading-spinner.vue';
import { fetchBrands, fetchProductsByBrandId, fetchCategoriesByBrandId } from '@/services/apiService';
import logo from "../assets/images/logo-branca.png"
import store from '@/store';
import { useI18n } from 'vue-i18n';
import ProductCard from '@/components/produtos/ProductCard.vue';
import { formatPrice } from '@/services/apiService'; 

const filteredProducts = ref([]);
const categories = ref([]);
const selectedTitle = ref('Produtos');
const sortOption = ref('');
const defaultImage = require('../assets/images/produto-sem-imagem.jpg');
const isLoading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);
const totalProducts = ref(0);
const route = useRoute();
const router = useRouter();
const brandId = ref(null);
const selectedCategory = ref(null);
const { locale } = useI18n();
const selectedPrintOption = ref('');

const fetchCategories = async () => {
  try {
    if (!brandId.value) return;

    const response = await fetch(`${process.env.VUE_APP_API_URI}/api/public/categories?brand_id=${brandId.value}`);
    const data = await response.json();

    categories.value = data;
  } catch (error) {
  }
};

const sortedProducts = computed(() => {
  return filteredProducts.value.map(product => {
    const price = parseFloat(product.valor_venda);
    let formattedPrice = '';

    if (!isNaN(price) && product.moeda) {
      formattedPrice = formatPrice(price, product.moeda.id);
    } else {
      formattedPrice = ''; 
    }

    return {
      ...product,
      formattedPrice: `${formattedPrice}`,
    };
  });
});
const handlePrintChange = () => {
  if (selectedPrintOption.value === 'grade') {
    printProductListGrid();
  } else if (selectedPrintOption.value === 'lista') {
    printProductListTable();
  } else if (selectedPrintOption.value === 'txt') {
    generateProductListTxt(); // Chama o método para gerar o arquivo TXT
  }

  // Remove a opção "PDF" da lista ao clicar
  if (selectedPrintOption.value === '') {
    const selectElement = document.getElementById('print-select');
    const pdfOption = selectElement.querySelector('option[value=""]');
    if (pdfOption) {
      pdfOption.remove();
    }
  }

  // Reseta a seleção
  selectedPrintOption.value = '';
};
const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  if (isNaN(precoNumerico)) return 'Preço inválido';

  // Obtém o idioma salvo no localStorage (sincronizado com Vuex)
  const idioma = localStorage.getItem('locale') || 'pt-BR';

  // Define o símbolo da moeda com base no idioma atual
  const simboloMoeda = idioma === 'es-PY' ? 'G$' : 'U$'; // Guarani para 'es-PY', Real para 'pt-BR'

  // Se for Guarani, remove os centavos
  return idioma === 'es-PY'
    ? `${simboloMoeda} ${precoNumerico.toFixed(0).replace('.', ',')}`
    : `${simboloMoeda} ${precoNumerico.toFixed(2).replace('.', ',')}`;
};

const fetchBrandBySlug = async (brandSlug) => {
  try {
    const brands = await fetchBrands();
    const brand = brands.find(b => b.slug === brandSlug);
    return brand ? { id: brand.id, name: brand.nome } : { id: null, name: '' };
  } catch (error) {
    console.error('Erro ao buscar marcas:', error);
    return { id: null, name: '' };
  }
};

const fetchProducts = async (page = 1) => {
  currentPage.value = page;
  isLoading.value = true;
  window.scrollTo(0, 0);

  if (!brandId.value) {
    isLoading.value = false;
    return;
  }

  try {
    const params = {
      page: currentPage.value,
      order_by: sortOption.value.split(':')[0] || 'tem_estoque,id',
      order: sortOption.value.split(':')[1] || 'desc,desc',
      category_id: selectedCategory.value ? selectedCategory.value.id : '',
    };

    const response = await fetchProductsByBrandId(brandId.value, params);

    filteredProducts.value = response.data || [];
    totalProducts.value = response.total || 0;
    totalPages.value = Math.ceil(totalProducts.value / 20);
  } catch (err) {
    console.error('Erro ao buscar produtos:', err);
  } finally {
    isLoading.value = false;
  }
};


const adicionarAoCarrinho = (product) => {
  if (product.tem_estoque) {
    store.commit('ecommerce/ADD_TO_CART', {
      ...product,
    });
  } else {
    alert('Este produto está indisponível no momento!');
  }
};


const selectCategory = async (category) => {
  selectedCategory.value = category || null;

  await router.push({
    name: 'marca',
    params: {
      brandSlug: route.params.brandSlug,
      categoriaSlug: category ? category.slug : null
    }
  });

  await fetchProducts();
};

const printProductListGrid = async () => {
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const printWindow = window.open('', '', 'width=800, height=600');
  const categoryTitle = selectedCategory.value ? ` - ${selectedCategory.value.nome}` : '';
  const headerTitle = selectedTitle.value;

  printWindow.document.write(`
    <html>
      <head>
        <style>
          /* Estilos para a impressão */
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          .print-header {
            width: 100%;
            padding: 10px 20px; 
            background-color: #000;
            color: #fff;
            border-bottom: 2px solid #ddd;
            margin-bottom: 10px; 
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            page-break-before: always;
          }
          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          .logo-container {
            flex: 1;
          }
          .store-logo {
            max-height: 120px;
            object-fit: contain;
            width: auto;
          }
          .header-text {
            flex: 2;
            text-align: right;
          }
          .date {
            font-size: 12px;
            color: #555;
            margin-bottom: 5px;
          }
          .price-disclaimer {
            font-size: 10px;
            color: #888;
            margin-bottom: 5px;
            font-style: italic;
          }

          .product-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            justify-items: center;
            margin: 20px auto;
            max-width: 100%;
          }
          .product-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ddd;
            padding: 10px;
            border-radius: 10px;
            width: 120px;
            height: 250px;
            box-sizing: border-box;
          }
          .product-image-container {
            width: 100%;
            height: 130px;
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .product-image {
            width: auto;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
          }
          .product-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            text-align: center;
          }
          .product-name {
            font-size: 0.6rem;
            font-family: 'Lato', sans-serif;
            color: #2b2b2b;
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 1.5;
            overflow: visible;
            text-overflow: initial;
            display: block;
          }
          .product-price {
            font-size: 16px;
            font-family: 'Lato', sans-serif;
            color: #3ea12a;
            font-weight: 700;
            margin: 5px 0;
          }
          .product-code {
            font-size: 12px;
            color: #757575;
            line-height: 1.667;
          }
          .out-of-stock-tag {
            color: red;
            font-weight: bold;
          }

          @media print {
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            @page {
              margin: 0;
            }
            * {
              box-sizing: border-box;
            }
            .product-list {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 10px;
              justify-items: center;
              margin: 20px auto;
              max-width: 100%;
              page-break-inside: avoid;
            }
            .product-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #ddd;
              padding: 10px;
              border-radius: 10px;
              width: 120px;
              height: 250px;
              box-sizing: border-box;
              page-break-inside: avoid;
            }
            .print-header {
              background-color: #000 !important;
              color: #fff !important;
              padding: 10px 20px;
              margin: 0;
            }
          }
        </style>
      </head>
      <body>
  `);

  printWindow.document.write(`
    <div class="print-header">
      <div class="header-content">
        <div class="logo-container">
          <img src="${logo}" alt="GENOVE" class="store-logo">
        </div>
        <div class="header-text">
          <p class="date">Data de Geração: ${new Date().toLocaleDateString()}</p>
          <p class="price-disclaimer">OS PREÇOS PODEM SOFRER ALTERAÇÕES SEM PRÉVIO AVISO.</p>
          <p class="price-disclaimer">OS VALORES NÃO INCLUEM I.V.A</p>
           <h2>${headerTitle}${categoryTitle}</h2>
        </div>
      </div>
    </div>
    <div class="product-list">
  `);

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const params = {
        brand_ids: [brandId.value],
        category_id: selectedCategory.value ? selectedCategory.value.id : '',
        page: currentPage,
        tem_estoque: true,
        all_products: 1
      };

      try {
        const data = await fetchProductsByBrandId(brandId.value, params);

        const filteredProducts = data.data.filter(product => product.tem_estoque === true);

        allProducts = allProducts.concat(filteredProducts);

        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
        break;
      }

      currentPage++;
    }

    return allProducts;
  };


  const productsToPrint = await fetchAllFilteredProducts();

  if (sortOption.value === 'valor_venda:asc') {
    productsToPrint.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToPrint.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToPrint.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToPrint.sort((a, b) => b.nome.localeCompare(a.nome));
  }

  if (productsToPrint.length === 0) {
    printWindow.document.write('<p>Nenhum produto disponível para exibição.</p>');
  } else {
    let productIndex = 0;
    const firstPageLimit = 15;
    const itemsPerPage = 20;

    for (let i = 0; i < firstPageLimit && productIndex < productsToPrint.length; i++) {
      const product = productsToPrint[productIndex];
      printWindow.document.write(`
     <div class="product-item">
       <div class="product-image-container">
         <img src="${product.imagens[0]?.url || defaultImage}" alt="Imagem do produto ${product.nome}" class="product-image"/>
       </div>
       <div class="product-info">
         <p class="product-name">${product.nome}</p>
         <p class="product-price">${formatarPreco(product.valor_venda)}</p>
         <p class="product-code">${product.codigo}</p>
       </div>
     </div>
   `);
      productIndex++;
    }

    while (productIndex < productsToPrint.length) {
      printWindow.document.write('</div><div style="page-break-after: always;"></div><div class="product-list">');
      for (let i = 0; i < itemsPerPage && productIndex < productsToPrint.length; i++) {
        const product = productsToPrint[productIndex];
        printWindow.document.write(`
       <div class="product-item">
         <div class="product-image-container">
           <img src="${product.imagens[0]?.url || defaultImage}" alt="Imagem do produto ${product.nome}" class="product-image"/>
         </div>
         <div class="product-info">
           <p class="product-name">${product.nome}</p>
           <p class="product-price">${formatarPreco(product.valor_venda)}</p>
           <p class="product-code">${product.codigo}</p>
         </div>
       </div>
     `);
        productIndex++;
      }
    }
  }

  printWindow.document.write('</div></body></html>');
  printWindow.document.close();
};
const printProductListTable = async () => {
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const printWindow = window.open('', '', 'width=800, height=600');
  const categoryTitle = selectedCategory.value ? ` - ${selectedCategory.value.nome}` : '';
  const headerTitle = selectedTitle.value;

  printWindow.document.write(`
    <html>
      <head>
        <style>
          /* Estilos para a impressão */
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          .print-header {
            width: 100%;
            padding: 10px 20px;
            background-color: #000;
            color: #fff;
            border-bottom: 2px solid #ddd;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            page-break-before: always;
          }
          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          .logo-container {
            flex: 1;
          }
          .store-logo {
            max-height: 120px;
            object-fit: contain;
            width: auto;
          }
          .header-text {
            flex: 2;
            text-align: right;
          }
          .date {
            font-size: 12px;
            color: #555;
            margin-bottom: 5px;
          }
          .price-disclaimer {
            font-size: 10px;
            color: #888;
            margin-bottom: 5px;
            font-style: italic;
          }

          .product-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px auto;
          }
          .product-table th, .product-table td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
          }
          .product-table th {
            background-color: #f4f4f4;
          }
          .product-code {
            font-size: 12px;
            color: #757575;
          }
          .product-name {
            font-size: 14px;
            font-weight: 500;
            color: #2b2b2b;
          }
          .product-price {
            font-size: 16px;
            color: #3ea12a;
            font-weight: 700;
          }

          @media print {
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            @page {
              margin: 0;
            }
            .product-table {
              width: 100%;
              border-collapse: collapse;
            }
            .product-table th, .product-table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center;
            }
            .product-table th {
              background-color: #f4f4f4;
            }
          }
        </style>
      </head>
      <body>
  `);

  printWindow.document.write(`
    <div class="print-header">
      <div class="header-content">
        <div class="logo-container">
          <img src="${logo}" alt="GENOVE" class="store-logo">
        </div>
        <div class="header-text">
          <p class="date">Data de Geração: ${new Date().toLocaleDateString()}</p>
          <p class="price-disclaimer">OS PREÇOS PODEM SOFRER ALTERAÇÕES SEM PRÉVIO AVISO.</p>
          <p class="price-disclaimer">OS VALORES NÃO INCLUEM I.V.A</p>
          <h2>${headerTitle}${categoryTitle}</h2>
        </div>
      </div>
    </div>
    <table class="product-table">
      <thead>
        <tr>
          <th>Código</th>
          <th>Título</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
  `);

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      // Definindo os parâmetros da requisição
      const params = {
        brand_ids: [brandId.value],
        category_id: selectedCategory.value ? selectedCategory.value.id : '', // Verifica se a categoria existe
        page: currentPage,
        tem_estoque: true, // Inclui o filtro para ter somente produtos com estoque
        all_products: 1
      };

      try {
        // Chama a função fetchProductsByBrandId com os parâmetros
        const data = await fetchProductsByBrandId(brandId.value, params);

        // Filtra os produtos para garantir que apenas os que têm estoque sejam incluídos
        const filteredProducts = data.data.filter(product => product.tem_estoque === true);

        // Concatena os produtos filtrados
        allProducts = allProducts.concat(filteredProducts);

        // Calcula o total de páginas
        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
        break;
      }

      currentPage++;
    }

    return allProducts;
  };

  const productsToPrint = await fetchAllFilteredProducts();

  // Aplica a ordenação no conjunto completo de produtos
  if (sortOption.value === 'valor_venda:asc') {
    productsToPrint.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToPrint.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToPrint.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToPrint.sort((a, b) => b.nome.localeCompare(a.nome));
  }

  if (productsToPrint.length === 0) {
    printWindow.document.write('<tr><td colspan="3" style="text-align: center;">Nenhum produto disponível para exibição.</td></tr>');
  } else {
    productsToPrint.forEach(product => {
      printWindow.document.write(`
        <tr>
          <td class="product-code">${product.codigo}</td>
          <td class="product-name">${product.nome}</td>
          <td class="product-price">${formatarPreco(product.valor_venda)}</td>
        </tr>
      `);
    });
  }

  printWindow.document.write(`
      </tbody>
    </table>
  </body>
</html>`);
  printWindow.document.close();
};
const generateProductListTxt = async () => {
  // Verifica se há produtos para exibir
  if (!filteredProducts.value || filteredProducts.value.length === 0) {
    alert('Não há produtos para exibir!');
    return;
  }

  const fetchAllFilteredProducts = async () => {
    let allProducts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      // Definindo os parâmetros da requisição
      const params = {
        brand_ids: [brandId.value],
        category_id: selectedCategory.value ? selectedCategory.value.id : '',
        page: currentPage,
        tem_estoque: true,
        all_products: 1 // Inclui o filtro para ter somente produtos com estoque
      };

      try {
        const data = await fetchProductsByBrandId(brandId.value, params);
        const filteredProducts = data.data.filter(product => product.tem_estoque === true);
        allProducts = allProducts.concat(filteredProducts);
        totalPages = Math.ceil(data.total / 20);
      } catch (err) {
        console.error('Erro ao carregar produtos', err);
        break;
      }

      currentPage++;
    }

    return allProducts;
  };

  const productsToExport = await fetchAllFilteredProducts();

  // Aplica a ordenação no conjunto completo de produtos
  if (sortOption.value === 'valor_venda:asc') {
    productsToExport.sort((a, b) => a.valor_venda - b.valor_venda);
  } else if (sortOption.value === 'valor_venda:desc') {
    productsToExport.sort((a, b) => b.valor_venda - a.valor_venda);
  } else if (sortOption.value === 'nome:asc') {
    productsToExport.sort((a, b) => a.nome.localeCompare(b.nome));
  } else if (sortOption.value === 'nome:desc') {
    productsToExport.sort((a, b) => b.nome.localeCompare(a.nome));
  }

  if (productsToExport.length === 0) {
    alert('Nenhum produto disponível para exportação.');
    return;
  }

  // Cabeçalho do arquivo
  const categoryTitle = selectedCategory.value ? ` - ${selectedCategory.value.nome}` : '';
  const headerTitle = selectedTitle.value;
  let content = `Lista de Preços (${new Date().toLocaleString()})\nGENOVE (www.genove.com.py)\n\n`;
  content += `Categoria: ${headerTitle}${categoryTitle}\n\n`;
  content += `Código - Descrição - Preço\n`;
  content += `---------------------------------------------------------------------------------------------------------------------------\n`;

  productsToExport.forEach(product => {
    const codigo = product.codigo.toString().padEnd(12, '').trim();
    const nome = product.nome.padEnd(90, '').trim();
    const valor = product.valor_venda
      ? `*U$ ${parseFloat(product.valor_venda).toFixed(2)}*`
      : '*N/A*';
    content += `${codigo} - ${nome} - ${valor}\n`;
  });

  // Cria o arquivo .txt
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'Lista_de_Precos.txt';
  link.click();


};


const visiblePages = computed(() => {
  let pages = [];
  if (totalPages.value <= 5) {
    pages = Array.from({ length: totalPages.value }, (_, i) => i + 1);
  } else {
    const start = Math.max(1, currentPage.value - 2);
    const end = Math.min(totalPages.value, start + 4);
    pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  return pages;
});

const goToPage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
    sessionStorage.setItem('currentPage', page);  // Armazena a página atual
    router.push({
      name: 'marca',
      params: {
        brandSlug: route.params.brandSlug,
        categoriaSlug: selectedCategory.value ? selectedCategory.value.slug : null
      },
      query: {
        ...route.query,
        page: page
      }
    });
    fetchProducts(page);
  }
};



const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    goToPage(currentPage.value + 1);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    goToPage(currentPage.value - 1);
  }
};
const updateURL = () => {
  router.push({
    name: 'marca',
    params: {
      brandSlug: route.params.brandSlug,
      categoriaSlug: selectedCategory.value ? selectedCategory.value.slug : null
    },
    query: {
      ...route.query,
      page: currentPage.value,
      sort: sortOption.value // Adiciona a opção de ordenação na URL
    }
  });
};


watch([() => route.params.brandSlug, () => route.params.categoriaSlug, () => route.query.page, () => route.query.sort],
  async ([brandSlug, categoriaSlug, page, sort], [oldBrandSlug, oldCategoriaSlug, oldPage, oldSort]) => {
    if (brandSlug !== oldBrandSlug) {
      const brand = await fetchBrandBySlug(brandSlug);
      brandId.value = brand.id;
      selectedTitle.value = brand.name || 'Produtos';
      await fetchCategories();
    }

    if (categoriaSlug) {
      const category = categories.value.find(c => c.slug === categoriaSlug);
      selectedCategory.value = category || null;
    } else {
      selectedCategory.value = null;
    }

    currentPage.value = parseInt(page) || 1;
    sortOption.value = sort || sessionStorage.getItem('sortOption') || ''; // Recupera a ordenação da URL ou do sessionStorage
    await fetchProducts(currentPage.value);
  });



watch(sortOption, (newSortOption) => {
  sessionStorage.setItem('sortOption', newSortOption);
  updateURL(); // Atualiza a URL para refletir a ordenação
  fetchProducts();
});



onMounted(async () => {
  sortOption.value = sessionStorage.getItem('sortOption') || '';
  const brand = await fetchBrandBySlug(route.params.brandSlug);
  brandId.value = brand.id;
  selectedTitle.value = brand.name || 'Produtos';
  await fetchCategories();

  if (route.params.categoriaSlug) {
    const category = categories.value.find(c => c.slug === route.params.categoriaSlug);
    if (category) {
      selectedCategory.value = category;
    }
  }

  currentPage.value = parseInt(sessionStorage.getItem('currentPage')) || 1;
  await fetchProducts(currentPage.value);
});

</script>


<style scoped>
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}

.categories-container {
  margin-bottom: 20px;
}

.category-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.category-item {
  margin-right: 15px;
}

.category-link {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.category-link:hover {
  background-color: #C99C5A;
  color: white;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.product-page {
  display: flex;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  max-width: 1500px;
  margin: 0 auto;

}

.category-sidebar {
  width: 280px;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  position: relative;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.category-item {
  position: relative;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #ffffff;
  border: 1px solid transparent;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
}

.category-link:hover,
.category-link.active {
  background-color: #C99C5A;
  color: #ffffff;
  border-color: #C99C5A;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.category-link i {
  transition: transform 0.3s ease;
}

.department-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 100%;
  top: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item:hover .department-list {
  display: block;
  opacity: 1;
}

.department-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.department-link:hover {
  background: #f5f5f5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.product-list-section {
  flex: 1;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}


.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  flex: 1;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.product-price {
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 5px 0;
  font-weight: 700;
  margin-top: 10px;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667;
  margin-top: auto;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    align-items: center;
  }

  .product-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 10px;
  position: relative;
}

.pagination-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, #C99C5A, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
}

.pagination-button:hover {
  background: linear-gradient(145deg, #C4A77D, #C99C5A);
  transform: scale(1.05);
}

.pagination-button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-numbers {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.pagination-numbers button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid transparent;
  border-radius: 50px;
  background: #fff;
  color: #C99C5A;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

.out-of-stock-tag {
  color: red;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
}

.pagination-numbers button.active {
  background: #C99C5A;
  color: #fff;
}

.pagination-numbers button:hover {
  background: #C99C5A;
  color: #fff;
}

@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }

  .pagination-numbers {
    display: none;
  }

  .pagination-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 15px;
  /* Espaçamento entre o botão e o seletor */
}

.sort-options select {
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid transparent;
  background-color: #707070;
  color: #e7c084;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sort-options select:hover,
.sort-options select:focus {
  border-color: #C99C5A;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.print-button-container .print-button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.print-button-container .print-button:hover {
  background-color: #f4f4f4;
  border-color: #888;
  color: #000;
}

.print-button-container .print-button:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.print-button-container .print-button:active {
  background-color: #e0e0e0;
  border-color: #707070;
  color: #333;
}

.print-button-container .print-button i {
  margin-right: 8px;
  /* Espaço entre o ícone e o texto */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;
}

.pagination-controls button {
  padding: 10px 20px;
  margin: 0 8px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, #C99C5A, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
  min-width: 100px;
  text-align: center;
}

.pagination-controls button:hover {
  background: linear-gradient(145deg, #C4A77D, #C99C5A);
  transform: scale(1.05);
}

.pagination-controls button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-controls span {
  font-size: 16px;
  margin: 0 10px;
  color: #333;
  font-weight: 600;
}


@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .print-button-container .print-button {
    display: none;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #c99c5a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.add-to-cart-container {
  margin-top: 10px;
  /* Ajusta a posição do botão */
}

.add-to-cart-btn {
  background-color: #C99C5A;
  /* Cor do botão */
  color: white;
  /* Cor do texto */
  border: none;
  border-radius: 10px;
  /* Cantos arredondados */
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #b38a4b;
  /* Cor do botão ao passar o mouse */
}

.add-to-cart-btn i {
  margin-right: 8px;
  /* Espaço entre o ícone e o texto */
}
@media (max-width: 640px) {
  #print-select-container {
    display: none;
  }
}
</style>
