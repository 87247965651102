<template>
  <div id="app">
    <Loading :isLoading="isLoading" />
    <header>
      <HeaderTopnav />
      <HeaderContent />
      <HeaderNavigation v-if="isDesktop" />
      <MobileMenu v-if="!isDesktop" :menuOpen="mobileMenuOpen" @toggle-menu="toggleMobileMenu" />
      <button v-if="!isDesktop && !mobileMenuOpen" @click="toggleMobileMenu" class="hamburger-btn">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
    </header>

    <router-view />

    <FooterContent />
<!-- 
    <button @click="openCart" class="cart-btn">
      <font-awesome-icon :icon="['fas', 'shopping-cart']" />
    </button> -->

    <CartView ref="cart" />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faChevronLeft, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import HeaderTopnav from './components/template/header-topnav.vue';
import HeaderContent from './components/template/header-content.vue';
import HeaderNavigation from './components/template/header-navigation.vue';
import FooterContent from './components/template/footer-content.vue';
import MobileMenu from './components/template/mobile-navigation.vue';
import CartView from './views/ecommerce/CartView.vue';
import { eventBus } from './eventBus';
import Loading from './components/template/loading-spinner.vue';

library.add(faBars, faChevronLeft, faShoppingCart);

export default {
  name: 'App',
  components: {
    HeaderTopnav,
    HeaderContent,
    HeaderNavigation,
    FooterContent,
    MobileMenu,
    FontAwesomeIcon,
    CartView,
  },
  setup() {
    const store = useStore();
    const isDesktop = ref(window.innerWidth > 991);
    const mobileMenuOpen = ref(false);
    const updateInterval = 3 * 60 * 1000;
    let intervalId;

    const startUpdating = () => {
      intervalId = setInterval(() => {
        store.dispatch('fetchCategories');
      }, updateInterval);
    };
    const loadCartWithDelay = () => {
      setTimeout(() => {
        store.dispatch('ecommerce/getCart');
      }, 500);
    };
    const handleResize = () => {
      isDesktop.value = window.innerWidth > 991;
    };

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };



    onMounted(() => {
      startUpdating();
      window.addEventListener('resize', handleResize);
      handleResize();

      loadCartWithDelay();
    });

    onUnmounted(() => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    });

    return {
      isDesktop,
      mobileMenuOpen,
      toggleMobileMenu,
      loadCartWithDelay
    };
  },
  methods: {
    openCart() {
      eventBus.emit('toggle-cart');
    },
  },
}
</script>


<style scoped>
.hamburger-btn {
  position: absolute;
  top: 70px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: 24px;
  color: #333;
  z-index: 1100;
  transition: color 0.3s;
}

.hamburger-btn:hover {
  color: #C99C5A;
}

.cart-btn {
  position: fixed;
  top: 60px;
  right: 16px;
  background-color: #C99C5A;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1300;
}

.cart-btn:hover {
  background-color: #b58447;
}
</style>
