<template>
  <div class="checkout-container">
    <!-- Título -->
    <h2 class="checkout-title">{{ $t('checkout.title') }}</h2>

    <!-- Indicador de Progresso -->
    <div class="checkout-steps">
      <div v-for="(step, index) in steps" :key="index"
        :class="['step', { active: currentStep === index, completed: index < currentStep }]">
        <div class="step-circle">
          <span v-if="index < currentStep">✔</span>
          <span v-else>{{ index + 1 }}</span>
        </div>
        <span class="step-title">{{ step.title }}</span>
      </div>
    </div>

    <!-- Carregando Estado -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading">
        <i class="fas fa-spinner fa-spin"></i> {{ $t('loading') }}
      </div>
    </div>

    <!-- Conteúdo -->
    <div v-else class="checkout-content">
      <div v-if="currentStep === 0">
        <h3 class="step-header">{{ $t('checkout.step1.title') }}</h3>
      
        <div v-if="isEmpty" class="empty-cart">
          <p>{{ $t('checkout.step1.empty_cart') }}</p>
          <router-link to="/" class="btn-back-to-shopping">
            {{ $t('checkout.step1.back_to_shopping') }}
          </router-link>
        </div>

        <div v-else>
          <div v-for="(item, index) in filteredCartItems" :key="index" class="cart-item">
            <img :src="getProductImage(item)" alt="Produto" class="item-image" />
            <div class="item-details">
              <h>{{ item.produto.codigo }}</h>
              <h4>{{ item.produto.nome }}</h4>
              <div class="item-actions">
                <button @click="updateQuantity(item, item.qtde - 1)" class="quantity-button">-</button>
                <span class="item-quantity">{{ Math.floor(item.qtde) }}</span>
                <button @click="updateQuantity(item, item.qtde + 1)" class="quantity-button">+</button>
              </div>
            </div>
            <div class="item-actions-container">
              <button @click="removeItem(item)" class="btn-remove" :disabled="removingItem === item.produto_id">
                <i v-if="removingItem === item.produto_id" class="fas fa-spinner fa-spin"></i>
                <i v-else class="fas fa-trash-alt"></i>
              </button>


              <p class="item-price">{{ formatPrice(item.valor_unitario) }}</p>
            </div>
          </div>

          <div class="total-price">
            <strong class="total-value">{{ totalPrice }}</strong>
            <span class="total-label">
              {{ $store.state.ecommerce.cart.moeda?.id === 3
                ? $t('checkout.step1.totalIva')
                : $t('checkout.step1.total') }}
            </span>
          </div>
            <!-- Componente de Cálculo de Frete -->
        <!-- <CalcularFretePy /> -->
          <div class="checkout-actions">
            <button v-if="isAuthenticated" @click="nextStep" class="btn-primary">
              {{ $t('checkout.step1.continue') }}
            </button>

            <div v-else class="login-warning">
              <p class="login-message">
                {{ $t('checkout.step1.login_message') }}
              </p>
              <div class="login-actions">
                <router-link :to="{ path: '/login', query: { redirect: 'checkout' } }" class="btn-login">
                  {{ $t('checkout.step1.login') }}
                </router-link>
                <router-link :to="{ name: 'cadastro', query: { redirect: 'checkout' } }" class="btn-register">
                  {{ $t('checkout.step1.register_now') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Etapa 2: Pagamento -->
      <div v-if="currentStep === 1">
        <div class="actions">
          <button @click="prevStep" class="btn-secondary">
            {{ $t('checkout.step2.back') }}
          </button>
          <button @click="nextStep" class="btn-primary" :disabled="!paymentMethod">
            {{ $t('checkout.step2.go_to_payment') }}
          </button>
        </div>
      </div>

      <!-- Exibindo erro se houver -->
      <div v-if="paymentError" class="error-message">
        <p>{{ $t('checkout.error.no_payment_method') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import defaultImage from '@/assets/images/produto-sem-imagem.jpg';
import CalcularFretePy from "./components/CalcularFretePy.vue";

export default {
  components: {
    CalcularFretePy,
  },
  data() {
    return {
      currentStep: 0,
      paymentMethod: null,
      paymentError: null,
      paymentExternalId: localStorage.getItem('paymentExternalId'),
      isLoading: false,
      removingItem: null,
      steps: [
        { title: this.$t('checkout.step1.title') },
        { title: this.$t('checkout.step2.payment') },
      ],
    };
  },
  computed: {
    isAuthenticated() {
      const token = localStorage.getItem('token');
      const tokenExpiry = localStorage.getItem('token_expiry');

      if (!token || !tokenExpiry) {
        return false;
      }

      const currentTime = new Date().getTime();
      if (currentTime > parseInt(tokenExpiry)) {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        return false;
      }

      return true;
    },
    isEmpty() {
      return this.filteredCartItems.length === 0;
    },
    filteredCartItems() {
      return (
        this.$store.state.ecommerce.cart.itens || []
      ).filter((item) => item.ativo !== false);
    },
    totalPrice() {
      const moeda = this.$store.state.ecommerce.cart.moeda;
      const total = Number(this.$store.state.ecommerce.cart.valor_total) || 0;
      const currencySymbol = moeda?.simbolo || "R$";

      if (currencySymbol === "U$") {
        return `U$ ${total.toFixed(2)}`;
      } else if (currencySymbol === "G$") {
        return `G$ ${total.toLocaleString('pt-BR')}`;
      } else {
        return `${currencySymbol} ${total.toFixed(2)}`;
      }
    },
  },

  mounted() {
    this.loadCart();
  },

  methods: {
    async loadCart() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('ecommerce/getCart');
        if (!this.$store.state.ecommerce.cart.moeda) {
          this.$store.commit('ecommerce/setCartCurrency', { id: 2, simbolo: 'U$' });
        }
      } catch (error) {
        console.error("Erro ao carregar carrinho:", error);
        alert(this.$t('checkout.error_request'));
      } finally {
        this.isLoading = false;
      }
    },
    getProductImage(item) {
      return item.produto.imagens?.[0]?.url || defaultImage;
    },
    nextStep() {
      if (!this.isAuthenticated) {
        this.currentStep = 0;
        return;
      }

      if (this.isEmpty) {
        alert(this.$t('checkout.step1.empty_cart'));
        return;
      }

      const externalId = this.$store.state.ecommerce.external_id;
      localStorage.setItem('paymentExternalId', externalId);

      const url = `${process.env.VUE_APP_API_URI}/ecom/venda/carrinho/${externalId}/iniciar-checkout`;

      const token = localStorage.getItem('token');

      if (!token) {
        alert(this.$t('checkout.step1.login_message'));
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      this.isLoading = true;

      axios
        .post(url, {}, { headers })
        .then((response) => {
          console.log("Payment link response:", response.data.link_pagamento);

          const paymentLink =
            response.data.link_pagamento && response.data.link_pagamento.length > 0
              ? response.data.link_pagamento[0]
              : null;

          if (paymentLink) {
            localStorage.setItem('paymentExternalId', externalId);

            this.$store.dispatch("ecommerce/clearCart");

            window.location.href = paymentLink;
          } else {
            console.error("Payment link not found.");
          }
        })
        .catch((error) => {
          console.error("Erro de requisição:", error);
          this.paymentError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    prevStep() {
      if (this.currentStep > 0) this.currentStep--;
    },

    removeItem(item) {
      this.removingItem = item.produto_id;

      this.$store.dispatch("ecommerce/removeFromCart", item).then(() => {
        return this.$store.dispatch('ecommerce/getCart');
      }).then(() => {
        this.removingItem = null;
      }).catch(() => {
        this.removingItem = null;
      });
    },


    updateQuantity(item, newQuantity) {
      const quantity = Math.max(1, Math.floor(newQuantity));
      if (quantity !== item.qtde) {
        this.$store.dispatch('ecommerce/updateProductQuantity', {
          produto_id: item.produto_id,
          qtde: quantity,
        }).then(() => {
          this.loadCart();
        });
      }
    },

    formatPrice(price) {
      const moeda = this.$store.state.ecommerce.cart.moeda;
      const currencySymbol = moeda?.simbolo || "R$";

      if (currencySymbol === "U$") {
        return `U$ ${parseFloat(price).toFixed(2)}`;
      } else if (currencySymbol === "G$") {
        return `G$ ${parseFloat(price).toLocaleString('pt-BR')}`;
      } else {
        return `${currencySymbol} ${parseFloat(price).toFixed(2)}`;
      }
    },
  },
};
</script>


<style scoped>
.checkout-container {
  max-width: 900px;
  margin: 20px auto;

  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  min-height: 80vh;
  text-align: center;
}



.register-now {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.step-header {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.loading {
  color: #3498db;
  font-size: 1.5rem;
  text-align: center;
}

.btn-login {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  background-color: #C99C5A;
  transition: background-color 0.3s;
  min-width: 200px;
  text-align: center;
  margin-bottom: 10px;
}

.btn-login:hover {
  background-color: #b58c4f;
}

.login-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0;
  top: 50%;
  left: 50%;
  width: 100%;
}


.login-message {
  font-size: 16px;
  color: #d32f2f;
  margin-bottom: 15px;
  text-align: center;
}

.login-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.register-message {
  font-size: 14px;
  color: #333;
  margin-top: 15px;
}

.btn-register {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
}

.btn-register:hover {
  color: #0056b3;
}

.checkout-title {
  font-size: 22px;
  color: #ffffff;
  background-color: #C99C5A;
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0 auto;
  box-shadow: none;
  border: none;
  text-align: center;
  text-decoration: none;
  margin-bottom: 30px;
}


.checkout-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.step {
  text-align: center;
  flex: 1;
  position: relative;
  color: #bbb;
  transition: color 0.3s;
}


.item-quantity {
  font-size: 16px;
  margin: 0 10px;
  color: #333;
}


.item-price {
  color: #C99C5A;
  font-size: 1.2em;
  margin-top: 30px;
}

.step.active {
  color: #C99C5A;
}

.step.completed {
  color: #C99C5A;
}

.step-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  background: #bbb;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.step.active .step-circle,
.step.completed .step-circle {
  background: #C99C5A;
}

.step-title {
  margin-top: 10px;
  font-size: 14px;
}

.step::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  background: #bbb;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.step:first-child::before {
  width: 0;
}

.step.active::before,
.step.completed::before {
  background: #C99C5A;
}

.checkout-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}



.item-image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 8px;
}

.item-details {
  flex: 1;

}



.item-details h4 {
  margin: 0;
  font-size: 18px;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  position: relative;
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  color: #333;
}

.btn-remove {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.btn-remove i,
.btn-remove svg {
  font-size: 18px;
  color: rgb(255, 95, 95);
}

.btn-remove:hover i,
.btn-remove:hover svg {
  transform: scale(1.2);
  color: #ff0000;
}


.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary {
  background: #C99C5A;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background: #C99C5A;
}

.btn-primary:disabled {
  background: #b0c4de;
}

.btn-secondary {
  background: #757575;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background: #616161;
}

.total-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

.total-value {
  font-size: 24px;
  color: #C99C5A;
}

.total-label {
  font-size: 14px;
  color: #C99C5A;
  margin-top: 10px;
}


.item-actions {
  display: flex;
  align-items: center;
}

.item-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}

.checkout-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.quantity-button {
  background-color: #C99C5A;
  color: white;
  border: 1px solid #C99C5A;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.quantity-button:hover {
  background-color: #b58c4f;
}

.btn-login {
  background-color: #C99C5A;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  min-width: 200px;
  text-align: center;
  font-size: 18px;
}

.btn-login:hover {
  background-color: #b58c4f;
}

.item-actions button {
  padding: 6px 12px;
  border: none;
  background-color: #C99C5A;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.item-actions button:hover {
  background-color: #b58c4f;
}
</style>
