
import axios from 'axios';
import store from '@/store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
});

const getMoedaId = (currentLanguage) => {
  switch (currentLanguage) {
    case 'es-PY':
      return 3;
    case 'pt-BR':
      return 2;
    default:
      return 2;
  }
};

const getCurrencySymbol = (moedaId) => {
  switch (moedaId) {
    case 3:
      return 'G$';
    case 2:
      return 'U$';
    default:
      return 'U$';
  }
};

export const formatPrice = (price, moedaId) => {
  const symbol = getCurrencySymbol(moedaId);
  
  if (moedaId === 3) { // Guarani (G$)
    return `${symbol} ${price.toLocaleString('es-PY', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  }

  return `${symbol} ${price.toLocaleString('pt-BR', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

api.interceptors.request.use((config) => {
  const currentLanguage = store.state.language.currentLanguage || 'pt-BR';
  const moedaId = getMoedaId(currentLanguage);
  config.params = { ...config.params, moeda_id: moedaId };
  return config;
});

export const fetchProducts = async (otherParams = {}) => {
  store.dispatch('general/setLoading', true);
  try {
    const moedaId = otherParams.moeda_id || getMoedaId(store.state.language.currentLanguage);
    store.commit('setMoedaId', moedaId);

    const response = await api.get('api/public/products', { params: { ...otherParams, moeda_id: moedaId } });
    const productData = response.data;

    productData.data.forEach(product => {
      if (product.preco_atual && product.moeda) {
        const price = parseFloat(product.valor_venda);
        product.preco_atual.formatado = formatPrice(price, moedaId);
      }
    });

    return productData;
  } catch (error) {
    console.error('Erro ao buscar produtos:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

export const fetchProductDetails = async (slug) => {
  store.dispatch('general/setLoading', true);
  try {
    const currentLanguage = store.state.language.currentLanguage || 'pt-BR';
    const langParam = currentLanguage === 'es-PY' ? 'es' : '';
    const url = `api/public/products/${slug}/details`;

    const response = await api.get(url, { params: { lang: langParam } });
    const productData = response.data;

    if (productData.preco_atual && productData.moeda) {
      const price = parseFloat(productData.preco_atual.valor_venda);
      const moedaId = getMoedaId(currentLanguage);
      productData.preco_atual.formatado = formatPrice(price, moedaId);
    }

    return productData;
  } catch (error) {
    console.error('Erro ao buscar detalhes do produto:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

export const getPriceWithCurrency = (price) => {
  const moedaId = store.state.moedaId || getMoedaId(store.state.language.currentLanguage);
  return formatPrice(price, moedaId);
};
// Função para buscar produtos de uma marca específica usando o brand_id
export const fetchProductsByBrandId = async (brandId, otherParams = {}) => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get('api/public/products', {
      params: { brand_id: brandId, ...otherParams },
    });
    const moedaId = otherParams.moeda_id || getMoedaId(store.state.language.currentLanguage);
    store.commit('setMoedaId', moedaId);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar produtos da marca:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

// Função para buscar categorias
export const fetchCategories = async (params = {}) => {
  store.dispatch('general/setLoading', true);
  try {
    const currentLanguage = store.state.language.currentLanguage || 'pt-BR'; 
    const langParam = currentLanguage === 'es-PY' ? '?lang=es' : '';
    
    const finalParams = { ...params, special: 'only' };

    const response = await api.get(`api/public/categories${langParam}`, { params: finalParams });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar categorias:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};



// Função para buscar marcas
export const fetchBrands = async () => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get('api/public/brands');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar marcas:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};


