<template>
  <div class="detalhes-container">
    <!-- Botão de Voltar -->
    <button class="back-button" @click="goBack">
      <i class="fas fa-arrow-left"></i> {{ $t('orders.back') }}
    </button>

    <h1>
      <i class="fas fa-info-circle"></i> {{ $t('orders.details') }}
    </h1>

    <!-- Mensagem de erro -->
    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>

    <!-- Indicador de carregamento -->
    <div v-if="loading" class="loading-indicator">
      <i class="fas fa-spinner fa-spin"></i> {{ $t('orders.loadingDetails') }}
    </div>

    <!-- Conteúdo do pedido -->
    <div v-else-if="pedido" class="detalhes-conteudo">
      <!-- Cabeçalho -->
      <div class="detalhes-header">
        <div v-for="(info, index) in headerInfo" :key="index" class="header-item">
          <span class="icon"><i :class="info.icon"></i></span>
          <p>
            <strong>{{ info.label }}:</strong> {{ info.value }}
          </p>
        </div>
      </div>

      <!-- Código de Retirada -->
      <div v-if="pedido.status === 'PAGO' && pedido.codigo_retirada" class="codigo-retirada">
        <p><strong>{{ $t('orders.pickup_code') }}:</strong> {{ pedido.codigo_retirada }}</p>
      </div>

      <!-- Lista de Produtos -->
      <div class="detalhes-itens">
        <h3>{{ $t('orders.product_list') }}</h3>
        <ul>
          <li v-for="item in pedido.itens" :key="item.id" class="item">
            <img :src="getProductImage(item.produto)" alt="Produto" class="produto-imagem" />
            <div class="item-info">
              <div class="item-column">
                <p><strong>{{ $t('orders.item_name') }}:</strong> {{ item.produto.nome }}</p>
                <p><strong>{{ $t('orders.item_code') }}:</strong> {{ item.produto.codigo }}</p>
              </div>
              <div class="item-column">
                <p><strong>{{ $t('orders.item_quantity') }}:</strong> {{ item.qtde }}</p>
                <p><strong>{{ $t('orders.item_unit_price') }}:</strong> {{ pedido.moeda.simbolo }} {{
                  item.valor_unitario }}</p>
                <p><strong>{{ $t('orders.item_subtotal') }}:</strong> {{ pedido.moeda.simbolo }} {{ item.valor_subtotal
                  }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="pedido.status === 'PAGO' && pedido.codigo_retirada" class="print-button-container">
        <button @click="printTicket" class="print-button">
          <i class="fas fa-print"></i> {{ $t('orders.print_ticket') }}
        </button>
      </div>
      <!-- Botão de pagamento -->
      <div v-if="pedido.link_pagamento && pedido.status === 'PAGAMENTO'" class="payment-button-container">
        <button @click="goToPaymentLink" class="payment-button">
          <i class="fas fa-credit-card"></i> {{ $t('orders.pay_now') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "PedidoDetalhes",
  props: ["external_id"],
  data() {
    return {
      pedido: null,
      errorMessage: "",
      loading: true,
    };
  },
  computed: {
    headerInfo() {
      if (!this.pedido) return [];
      return [
        {
          label: this.$t("orders.pedido"),
          value: this.pedido.pedido,
          icon: "fas fa-box",
        },
        {
          label: this.$t("orders.date"),
          value: this.formatDate(this.pedido.created_at),
          icon: "fas fa-calendar-alt",
        },
        {
          label: this.$t("orders.payment_status"),
          value: this.pedido.pagamentos[0].status,
          icon: "fas fa-credit-card",
        },
        {
          label: this.$t("orders.payment_value"),
          value: `${this.pedido.moeda.simbolo} ${this.pedido.pagamentos[0].valor_total}`,
          icon: "fas fa-dollar-sign",
        },
      ];
    },
  },
  methods: {
    async printTicket() {
      if (!this.pedido) return;

      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const logo = new Image();
      logo.src = require('@/assets/images/logo.png');

      logo.onload = () => {
        // Tamanho e posição da logo
        const imgWidth = 40;
        const imgHeight = (logo.height / logo.width) * imgWidth;
        const xPos = (pageWidth - imgWidth) / 2;
        const yPos = 10;

        doc.addImage(logo, 'PNG', xPos, yPos, imgWidth, imgHeight);

        // Cabeçalho do pedido
        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.text(this.$t("orders.details"), 105, yPos + imgHeight + 10, null, null, "center");

        doc.setLineWidth(0.5);
        doc.line(10, yPos + imgHeight + 15, 200, yPos + imgHeight + 15);

        // Informações do pedido
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        let textY = yPos + imgHeight + 25;

        doc.text(`${this.$t("orders.pedido")}: ${this.pedido.pedido}`, 15, textY);
        doc.text(`${this.$t("orders.date")}: ${this.formatDate(this.pedido.created_at)}`, 15, textY + 10);
        doc.text(`${this.$t("orders.payment_status")}: ${this.pedido.pagamentos[0].status}`, 15, textY + 20);
        doc.text(`${this.$t("orders.payment_value")}: ${this.pedido.moeda.simbolo} ${this.pedido.pagamentos[0].valor_total}`, 15, textY + 30);

        if (this.pedido.status === "PAGO" && this.pedido.codigo_retirada) {
          doc.setFont("helvetica", "bold");
          doc.text(`${this.$t("orders.pickup_code")}: ${this.pedido.codigo_retirada}`, 15, textY + 40);
        }

        doc.setLineWidth(0.5);
        doc.line(10, textY + 50, 200, textY + 50);

        // Tabela de Itens
        const tableColumn = [
          this.$t("orders.item_name"),
          this.$t("orders.item_code"),
          this.$t("orders.item_quantity"),
          this.$t("orders.item_unit_price"),
          this.$t("orders.item_subtotal"),
        ];
        const tableRows = [];

        this.pedido.itens.forEach((item) => {
          tableRows.push([
            item.produto.nome,
            item.produto.codigo,
            item.qtde,
            `${this.pedido.moeda.simbolo} ${item.valor_unitario}`,
            `${this.pedido.moeda.simbolo} ${item.valor_subtotal}`,
          ]);
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: textY + 55,
          margin: { top: 5, bottom: 20 },
          theme: "grid",
          headStyles: { fillColor: [201, 156, 90] }, // Dourado #C99C5A
          styles: { cellPadding: 3, fontSize: 10 },
        });

        doc.save(`Pedido_${this.pedido.pedido}.pdf`);
      };
    }

    ,

    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleString("pt-BR", options);
    },
    async fetchPedidoDetails() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.errorMessage = this.$t("orders.token_error");
        this.loading = false;
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URI}/ecom/venda/pedidos/${this.external_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data) {
          this.pedido = response.data;
        } else {
          this.errorMessage = this.$t("orders.no_details_found");
        }
      } catch (error) {
        this.errorMessage = this.$t("orders.fetch_error");
      } finally {
        this.loading = false;
      }
    },
    getProductImage(produto) {
      return (
        produto.imagens[0]?.url ||
        require("@/assets/images/produto-sem-imagem.jpg")
      );
    },
    goBack() {
      this.$router.go(-1);
    },
    goToPaymentLink() {
      if (this.pedido.link_pagamento) {
        window.location.href = this.pedido.link_pagamento;
      } else {
        this.errorMessage = this.$t("orders.payment_link_not_found");
      }
    },
  },
  created() {
    this.fetchPedidoDetails();
  },
};
</script>

<style scoped>
.detalhes-container {
  background: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  min-height: 100vh;
}

h1 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.payment-button {
  background: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.print-button {
  background: #27ae60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.print-button:hover {
  background: #219150;
}

.codigo-retirada {
  background: #f7f9fa;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  border: 2px dashed #3498db;
  color: #2c3e50;
}

.payment-button:hover {
  background: #2980b9;
}

.back-button {
  background: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.back-button:hover {
  background: #2980b9;
}

.error-message {
  color: #e74c3c;
  font-size: 1.2rem;
  text-align: center;
}

.loading-indicator {
  color: #3498db;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}

.detalhes-header {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.header-item {
  flex: 1 1 45%;
  background: #f7f9fa;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.header-item .icon {
  font-size: 1.5rem;
  color: #3498db;
}

.detalhes-itens h3 {
  margin-bottom: 1rem;
  color: #34495e;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  border-left: 5px solid #3498db;
}

.produto-imagem {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.item-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: bold;
  color: #2c3e50;
}

.item:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
  transition: all 0.2s;
}
</style>
