<template>
  <div class="product-item">
    <router-link :to="`/produto/${product.slug}?page=${currentPage}`" class="product-link">
      <div class="product-image-container">
        <img :src="product.imagens[0]?.url || defaultImage" :alt="`Imagem do produto ${product.nome}`"
          class="product-image" />
      </div>
    </router-link>

    <button @click="adicionarAoCarrinho(product)" :class="[
      produtosAddCart.includes(product.id) ? 'added-to-cart' : 'add-to-cart-btn',
      { 'button-animation': isAnimating }
    ]" v-if="product.tem_estoque">
      <i class="fas" :class="produtosAddCart.includes(product.id) ? 'fa-check' : 'fa-cart-plus'"></i>
      <span v-if="isAnimating">{{ $t('categories.addedToCart') }}</span>
      <span v-else>{{ $t('categories.addToCart') }}</span>
    </button>


    <router-link :to="`/produto/${product.slug}?page=${currentPage}`" class="product-link">
      <div class="product-info">
        <p class="product-name">{{ product.nome }}</p>
        <p v-if="product.tem_estoque" class="product-price">{{ product.formattedPrice }}</p>
        <p class="product-code">{{ product.codigo }}</p>
        <small v-if="!product.tem_estoque" class="out-of-stock-tag">{{ $t('categories.outOfStock') }}</small>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { eventBus } from '@/eventBus';

const props = defineProps({
  product: Object,
  currentPage: Number,
  produtosAddCart: Array,
  defaultImage: String,
});

const store = useStore();
const { t: $t } = useI18n();

const isAnimating = ref(false);

const adicionarAoCarrinho = (product) => {
  if (product.tem_estoque) {
    store.commit('ecommerce/ADD_TO_CART', { ...product });
    store.commit('ecommerce/TOGGLE_CART_VISIBILITY', true);
    eventBus.emit('toggle-cart'); 

    // Inicia animação e texto "Produto Adicionado"
    isAnimating.value = true;

    // Remove animação após 1.2s
    setTimeout(() => {
      isAnimating.value = false;
    }, 1200);
  } else {
    alert('Este produto está indisponível no momento!');
  }
};
</script>


<style scoped>
.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  flex: 1;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.out-of-stock-tag {
  color: red;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.product-price {
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 3px 0;
  font-weight: 700;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667;
  margin-bottom: 30px;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.add-to-cart-overlay {
  background-color: #C99C5A;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-to-cart-overlay:hover {
  background-color: #b2884d;
  transform: scale(1.05);
}

.add-to-cart-overlay i {
  margin-right: 10px;
  font-size: 18px;
}

.add-to-cart-btn,
.added-to-cart {
  background-color: #C99C5A;
  color: white;
  border: none;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-to-cart-btn:hover,
.added-to-cart:hover {
  background-color: #b2884d;
  transform: scale(1.05);
}

.add-to-cart-btn i,
.added-to-cart i {
  margin-right: 4px;
  font-size: 12px;
}

.button-animation {
  animation: button-click 0.5s ease-out;
}

@keyframes button-click {
  0% {
    transform: scale(1);
    background-color: #c99c5a;
  }

  50% {
    transform: scale(1.1);
    background-color: #b2884d;
  }

  100% {
    transform: scale(1);
    background-color: #c99c5a;
  }
}
</style>