<template>
  <div class="pedido-detalhes-container">
    <div v-if="paymentCompleted" class="parabens-container">
      <div class="parabens-box">
        <span class="icon-check">🎉</span>
        <h2>{{ $t('orders.payment_success') }}</h2>
        <p>{{ $t('orders.congratulations_message') }}</p>
        <div v-if="paymentCompleted && pedido.status === 'PAGO' && pedido.codigo_retirada" class="codigo-retirada">
          <p><strong>{{ $t('orders.pickup_code') }}:</strong> {{ pedido.codigo_retirada }}</p>
        </div>
        <div class="button-group">
          <button class="btn-primary" @click="redirectToOrders">{{ $t('orders.view_orders') }}</button>
          <button class="btn-secondary" @click="printTicket">{{ $t('orders.print_ticket') }}</button>
        </div>
      </div>
    </div>

    <div v-else-if="loading" class="loader-container">
      <div class="loader-animation"></div>
      <p>{{ $t('orders.waiting_for_payment') }}</p>
    </div>

    <div v-else class="error-container">
      <p>{{ $t('orders.payment_not_found') }}</p>
      <p>{{ $t('orders.contact_store') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: 'PedidoDetalhes',
  data() {
    return {
      pedido: null,
      paymentCompleted: false,
      loading: true,
      attempts: 0,
      maxAttempts: 30,
      intervalId: null,
      paymentExternalId: localStorage.getItem('paymentExternalId'),
    };
  },
  methods: {
    async fetchPedidoDetails() {
      const token = localStorage.getItem('token');
      if (!token || !this.paymentExternalId) return;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URI}/ecom/venda/pedidos/${this.paymentExternalId}/buscar-pagamentos`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const pagamentoStatus = response.data?.pagamentos?.[0]?.status;
        if (pagamentoStatus === 'FINALIZADO' || pagamentoStatus === 'PAGO') {
          this.paymentCompleted = true;
          this.pedido = response.data;
          this.loading = false;
          clearInterval(this.intervalId);
        } else {
          this.attempts++;
          if (this.attempts >= this.maxAttempts) {
            this.loading = false;
            this.showErrorMessage();
            clearInterval(this.intervalId);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do pedido.', error);
        this.loading = false;
        this.showErrorMessage();
        clearInterval(this.intervalId);
      }
    },

    showErrorMessage() {
      this.errorMessage = this.$t('orders.payment_not_found');
    },

    redirectToOrders() {
      this.$router.push('/perfil/meus-pedidos');
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleString("pt-BR", options);
    },
    async printTicket() {
  if (!this.pedido) return;

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const logo = new Image();
  logo.src = require('@/assets/images/logo.png');

  logo.onload = () => {
    // Tamanho e posição da logo
    const imgWidth = 40;
    const imgHeight = (logo.height / logo.width) * imgWidth;
    const xPos = (pageWidth - imgWidth) / 2;
    const yPos = 10;

    doc.addImage(logo, 'PNG', xPos, yPos, imgWidth, imgHeight);

    // Cabeçalho do pedido
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    doc.text(this.$t("orders.details"), 105, yPos + imgHeight + 10, null, null, "center");

    doc.setLineWidth(0.5);
    doc.line(10, yPos + imgHeight + 15, 200, yPos + imgHeight + 15);

    // Informações do pedido
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    let textY = yPos + imgHeight + 25;

    doc.text(`${this.$t("orders.pedido")}: ${this.pedido.pedido}`, 15, textY);
    doc.text(`${this.$t("orders.date")}: ${this.formatDate(this.pedido.created_at)}`, 15, textY + 10);
    doc.text(`${this.$t("orders.payment_status")}: ${this.pedido.pagamentos[0].status}`, 15, textY + 20);
    doc.text(`${this.$t("orders.payment_value")}: ${this.pedido.moeda.simbolo} ${this.pedido.pagamentos[0].valor_total}`, 15, textY + 30);

    if (this.pedido.status === "PAGO" && this.pedido.codigo_retirada) {
      doc.setFont("helvetica", "bold");
      doc.text(`${this.$t("orders.pickup_code")}: ${this.pedido.codigo_retirada}`, 15, textY + 40);
    }

    doc.setLineWidth(0.5);
    doc.line(10, textY + 50, 200, textY + 50);

    // Tabela de Itens
    const tableColumn = [
      this.$t("orders.item_name"),
      this.$t("orders.item_code"),
      this.$t("orders.item_quantity"),
      this.$t("orders.item_unit_price"),
      this.$t("orders.item_subtotal"),
    ];
    const tableRows = [];

    this.pedido.itens.forEach((item) => {
      tableRows.push([
        item.produto.nome,
        item.produto.codigo,
        item.qtde,
        `${this.pedido.moeda.simbolo} ${item.valor_unitario}`,
        `${this.pedido.moeda.simbolo} ${item.valor_subtotal}`,
      ]);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: textY + 55,
      margin: { top: 5, bottom: 20 },
      theme: "grid",
      headStyles: { fillColor: [201, 156, 90] }, // Dourado #C99C5A
      styles: { cellPadding: 3, fontSize: 10 },
    });

    doc.save(`Pedido_${this.pedido.pedido}.pdf`);
  };
}


  },
  created() {
    this.fetchPedidoDetails();
    this.intervalId = setInterval(this.fetchPedidoDetails, 5000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style scoped>
.pedido-detalhes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background: #f8f9fa;
  padding: 20px;
  padding-top: 50px;
}

.loader-container {
  text-align: center;
  margin-top: 20px;
}

.loader-animation {
  width: 50px;
  height: 50px;
  border: 4px solid #e0e0e0;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.loader-container p {
  font-size: 1.2rem;
  color: #495057;
  margin-top: 10px;
}

.error-container {
  text-align: center;
  color: #e74c3c;
  font-size: 1.2rem;
  margin-top: 20px;
}

.parabens-container {
  text-align: center;
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
}

.parabens-box .icon-check {
  font-size: 4rem;
  color: #28a745;
  animation: pop 0.5s ease;
}

.parabens-box h2 {
  font-size: 2rem;
  color: #28a745;
  margin: 1rem 0;
}

.parabens-box p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.codigo-retirada {
  background: #f7f9fa;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  border: 2px dashed #28a745;
  color: #28a745;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pop {
  0% {
    transform: scale(0.9);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
