<template>
  <div :class="['header-content', { 'apply-transform': !isAuthenticated }]" @mousedown="handleMouseDown">

    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- Logo -->
        <div class="col-lg-3 col-md-4 text-md-start text-center">
          <div class="logo">
            <a href="/">
              <img width="167" height="69" src="@/assets/images/logo.png" class="img-fluid" alt="logo">
            </a>
          </div>
        </div>

        <!-- Search Bar -->
        <div class="col-lg-6 col-md-6 d-flex justify-content-between align-items-center">
          <form @submit.prevent="searchProducts" class="header-search-bar">
            <div class="input-group">
              <input v-model="searchText" @input="debouncedHandleInput" @focus="showSuggestions = true"
                @blur="handleBlur" @keydown.enter="handleEnter" type="search" name="search"
                :placeholder="$t('search.placeholder')" class="search-input" autocomplete="off" />

              <button type="submit" class="search-button">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div v-if="loading" class="loader"></div>
            <ul v-if="showSuggestions && filteredProducts.length" class="autocomplete-suggestions">
              <li v-for="(product, index) in limitedFilteredProducts" :key="product.id" @click="selectProduct(product)">
                <img :src="product.imagens[0]?.url || ProdutoSemImagem" alt="Product Image" />
                <span v-html="highlightMatch(product.nome)"></span>
              </li>
            </ul>
          </form>
        </div>

        <!-- Login/Logout Buttons -->
        <div class="col-lg-3 col-md-2 d-flex justify-content-end align-items-center">
          <!-- Ocultar em telas pequenas e médias -->
          <a v-if="!isAuthenticated" href="/login" class="login-button d-none d-md-flex">
            <div class="login-icon-wrapper">
              <i class="fas fa-user login-icon"></i>
            </div>
            <span class="login-text">{{ $t('login2') }}</span>
          </a>
          <a v-if="isAuthenticated" href="/perfil" class="login-button d-none d-md-flex">
            <div class="login-icon-wrapper">
              <i class="fas fa-user login-icon"></i>
            </div>
            <span class="login-text">{{ $t('greeting') }}, {{ userNome }}</span>
          </a>

          <!-- Carrinho de Compras -->
          <a @click="openCart" class="cart-button d-none d-md-flex">
            <div class="cart-icon-wrapper">
              <i class="fas fa-shopping-cart cart-icon"></i>
            </div>
            <span class="cart-text">{{ $t('cart.title') }}</span>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
import ProdutoSemImagem from '@/assets/images/produto-sem-imagem.jpg';
import { eventBus } from '@/eventBus';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const searchText = ref('');
const filteredProducts = ref([]);
const showSuggestions = ref(false);
const loading = ref(false);
const confirmLogout = ref(false);
const router = useRouter();
const store = useStore();
const user = computed(() => store.getters['auth/getUser']);
const userNome = computed(() => user.value?.nome || t('welcome'));
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

const limitedFilteredProducts = computed(() => filteredProducts.value.slice(0, 4));

const openCart = () => {
  eventBus.emit('toggle-cart');
};

const logout = () => {
  if (!confirmLogout.value) {
    confirmLogout.value = true;
    setTimeout(() => {
      confirmLogout.value = false;
    }, 5000);
  } else {
    store.dispatch('auth/logout');
    localStorage.removeItem('token');
    router.push('/login');
  }
};

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};
const handleEnter = (event) => {
  searchProducts(); 
  event.target.blur(); 
};

const searchProducts = async () => {
  if (searchText.value.trim()) {
    try {
      showSuggestions.value = false;
      await router.push({ name: 'produtos', query: { text: searchText.value } });
    } catch (err) {
      console.error('Erro ao navegar para produtos:', err);
    }
  } else {
    try {
      await router.push({ name: 'home' });
    } catch (err) {
      console.error('Erro ao navegar para home:', err);
    }
  }
};

const fetchProducts = async () => {
  const searchValue = searchText.value.trim();

  if (searchValue.length < 2) {
    return;
  }

  let apiUrl = `${process.env.VUE_APP_API_URI}/api/public/products?text=${searchValue}&order_by=p_text&order=asc&all_products=1`;

  loading.value = true;
  try {
    const response = await axios.get(apiUrl);
    filteredProducts.value = response.data.data.filter(product => product.tem_estoque);
  } catch (error) {
    console.error('Erro ao buscar produtos:', error);
  } finally {
    loading.value = false;
  }
};

const debouncedHandleInput = debounce(() => {
  if (searchText.value.trim()) {
    showSuggestions.value = true;
  }
  fetchProducts();
}, 300);

const highlightMatch = (text) => {
  const regex = new RegExp(`(${searchText.value})`, 'gi');
  return text.replace(regex, (match) => `<strong>${match}</strong>`);
};

const selectProduct = (product) => {
  searchText.value = product.nome;
  showSuggestions.value = false;

  try {
    router.push({
      name: 'produto',
      params: { slug: product.slug },
    });
  } catch (err) {
    console.error('Erro ao navegar para o detalhe do produto:', err);
  }
};

const handleBlur = () => {
  setTimeout(() => {
    if (!document.querySelector('.autocomplete-suggestions:hover')) {
      showSuggestions.value = false;
    }
  }, 200);
};

const handleMouseDown = (event) => {
  if (!document.querySelector('.header-search-bar').contains(event.target)) {
    showSuggestions.value = false;
  }
};

watch(searchText, (newVal) => {
  if (newVal.trim() && !loading.value) {
    showSuggestions.value = true;
  } else {
    showSuggestions.value = false;
  }
});
</script>


<style scoped>
.header-content {
  padding: 10px 0;
  background-color: #f4f4f4;
  border-top: 3px solid #ddd;
  margin-bottom: 15px;
  position: relative;
}
@media (min-width: 1000px) {
  .header-content {
    transform: translateY(-20px); 
  }
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 15px
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.header-search-bar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 600px;
}

.login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 20px;
}


.login-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: #C99C5A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.login-icon {
  color: white;
  font-size: 18px;
}

.login-button:hover .login-icon-wrapper {
  background-color: #b48851;
}

.login-text {
  text-align: center;
  color: #777777;
}

.login-button:hover .login-text {
  color: #b48851;
}

.login-button.confirm-logout {
  background-color: red;
  color: white;
}

.input-group {
  display: flex;
  align-items: center;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
}

.search-button {
  border: none;
  background-color: #C99C5A;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.search-button i {
  font-size: 18px;
}

.cart-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}

.cart-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: #C99C5A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.cart-icon {
  color: white;
  font-size: 18px;
}

.cart-button:hover .cart-icon-wrapper {
  background-color: #b48851;
}



.cart-button:hover .cart-text {
  color: #b48851;
  
}
.cart-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.col-lg-3.col-md-2.d-flex {
  align-items: flex-start !important;
}
.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-suggestions li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.autocomplete-suggestions li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

.autocomplete-suggestions li span {
  font-weight: bold;
  font-size: 18px;
}

.autocomplete-suggestions li span strong {
  color: #C99C5A;
}

.autocomplete-suggestions li:hover {
  background: #f4f4f4;
}

.loader {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #C99C5A 0%, rgba(201, 156, 90, 0) 50%, rgba(201, 156, 90, 0) 100%);
  background-size: 200% 100%;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.cart-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  height: 100%;
}

.cart-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: #C99C5A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.cart-icon {
  color: white;
  font-size: 18px;
}

.cart-text {
  text-align: center;
  color: #777777;
  margin-top: 0;
}

.cart-button:hover .cart-icon-wrapper {
  background-color: #b48851;
}

.cart-button:hover .cart-text {
  color: #b48851;
}

/* Esconde os botões de login, logout e carrinho em telas menores que 'md' */
@media (max-width: 991px) {

  .login-button,
  .cart-button {
    display: none !important;
  }
}
</style>